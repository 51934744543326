import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";


import Home from './components/Home';
import Questionaire from './components/Questionaire';
import Canvas from './components/Canvas';
import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import ForgotPassword from "./components/ForgotPassword";
import Editor from "./components/Editor";
import Navbar from "./components/Navbar";
import ChatWindow from "./components/ChatWindow";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import Dashboard_Detail from "./components/Dashboard-Detail/Dashboard_Detail";
import Anup from "./components/anup/Anup";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/qna" element={<Questionaire />} />
        <Route path="/canvas" element={<Canvas/>} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />

        {/* For dev asn testing purposes only */}
        <Route path="/editor" element={<Editor/>} />
        <Route path="/signin" element={<SignIn/>} />
        <Route path="/anup" element={<Anup/>}/>
        <Route path="/chat" element={<ChatWindow/>} />
        <Route path="/dashdetail" element={<Dashboard_Detail/>} />
        <Route path="/navbar" element={<Navbar stepNumber={1}/>} />
        

        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </Router>
  );
}

export default App;
