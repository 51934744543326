import React from 'react';
import styles from './SignUp.module.css';
import { useNavigate } from 'react-router-dom';

interface SignUpProps {}

const SignUp: React.FC<SignUpProps> = () => {

    const navigate = useNavigate();

    const navigateToSignIn = () => {
      navigate('/SignIn');
    }

    return (
        <>
    <div className={styles.sign_up}>
      <div className={styles.frame_299}>
        <div className={styles.frame_357}>
          <div className={styles.frame_354}>
            <div className={styles.frame_2608483}>
              <div className={styles.frame_20}>
                <img className={styles.ellipse_10} src="/images/SignUp/ellipse-100.png" />
              </div>
              <div className={styles.ai_gen}>Appefy</div>
            </div>
            <div className={styles.sign_up2}>Sign up</div>
          </div>
          <div className={styles.frame_2608492}>
            <div className={styles.already_have_an_account}>Already have an account?</div>
            <div className={styles.sign_up_here}>
              <div className={styles.frame_2608493}>
                <div className={styles.navlink} onClick={navigateToSignIn}>Sign in here</div>
              </div>
            </div>
          </div>
          <div className={styles.frame_3}>
            <div className={styles.frame_2608485}>
              <div className={styles.input}>
                <div className={styles.field}>
                  <div className={styles.input_content}>
                    <div className={styles.search}><input type="text" className={styles.input_content} placeholder='Full name' aria-label="Full name"/></div>
                  </div>
                </div>
              </div>
              <div className={styles.input2}>
                <div className={styles.field}>
                  <div className={styles.input_content}>
                    <div className={styles.search}><input type="text" className={styles.input_content} placeholder='Email' aria-label="Email"/></div>
                  </div>
                </div>
              </div>
              <div className={styles.input}>
                <div className={styles.field}>
                  <div className={styles.input_content}>
                    <div className={styles.search}><input type="password" className={styles.input_content} placeholder='Password' aria-label="Password"/></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.button_panel}>
              <div className={styles.button_frame}>
                <div className={styles.button_text}>Next</div>
              </div>
            </div>
            <div className={styles.frame_2608494}>
              <div className={styles.frame_2608477}>
                <div className={styles.line_54}></div>
                <div className={styles.or_continue_with}>Or continue with</div>
                <div className={styles.line_55}></div>
              </div>
              <div className={styles.frame_2608482}>
                <div className={styles.frame_3472}>
                  <div className={styles.image_10}>
                    <img className={styles.image_102} src="/images/SignUp/image-101.png" />
                  </div>
                  <div className={styles.accept_all_cockies2}>Sign in With Google</div>
                </div>
                <div className={styles.frame_2608479}>
                  <div className={styles.image_11}>
                    <img className={styles.image_112} src="/images/SignUp/image-111.png" />
                  </div>
                  <div className={styles.accept_all_cockies2}>Sign in With Outlook</div>
                </div>
                <div className={styles.frame_2608480}>
                  <div className={styles.image_12}>
                    <img className={styles.image_122} src="/images/SignUp/image-121.png" />
                  </div>
                  <div className={styles.accept_all_cockies2}>Sign in With Facebook</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
    );
};

export default SignUp;
