import React from "react";
import styles from "./ChatWindow.module.css";

interface ChatWindowProps {}

interface ChatWindowState {}

export default class ChatWindow extends React.Component<
  ChatWindowProps,
  ChatWindowState
> {
  render() {
    return (
      <div className={styles.chat_frame}>
      <div className={styles.chat}>
        <div className={styles.chat_header}>
          <div className={styles.frame_272}>
            <img className={styles.ellipse_102} src="/images/qna/ellipse-101.png" />
            <div className={styles.ai_builder}>Appefy Builder</div>
          </div>
          <div className={styles.remix_icons_line_system_question_line}>
            <svg
              className={styles.group5}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM11 15H13V17H11V15ZM13 13.355V14H11V12.5C11 12.2348 11.1054 11.9804 11.2929 11.7929C11.4804 11.6054 11.7348 11.5 12 11.5C12.2841 11.5 12.5623 11.4193 12.8023 11.2673C13.0423 11.1154 13.2343 10.8984 13.3558 10.6416C13.4773 10.3848 13.5234 10.0988 13.4887 9.81684C13.454 9.53489 13.34 9.26858 13.1598 9.04891C12.9797 8.82924 12.7409 8.66523 12.4712 8.57597C12.2015 8.48671 11.912 8.47587 11.6364 8.54471C11.3608 8.61354 11.1104 8.75923 10.9144 8.96482C10.7183 9.1704 10.5847 9.42743 10.529 9.706L8.567 9.313C8.68863 8.70508 8.96951 8.14037 9.38092 7.67659C9.79233 7.2128 10.3195 6.86658 10.9086 6.67332C11.4977 6.48006 12.1275 6.44669 12.7337 6.57661C13.3399 6.70654 13.9007 6.99511 14.3588 7.41282C14.8169 7.83054 15.1559 8.36241 15.3411 8.95406C15.5263 9.54572 15.5511 10.1759 15.4129 10.7803C15.2747 11.3847 14.9785 11.9415 14.5545 12.3939C14.1306 12.8462 13.5941 13.1779 13 13.355Z"
                fill="#AEAEAE"
              />
            </svg>
          </div>
        </div>

        <div className={styles.chat_messages_view} id="chat_history">
          <div className={`${styles.chat_bubble} ${styles.sender}`}>
            Hi there! How can I help you?
          </div>
          <div className={`${styles.chat_bubble} ${styles.receiver}`}>
            I'm looking for more information about your products. I'm looking
            for more information about your products.I'm looking for more
            information about your products.I'm looking for more information
            about your products. I'm looking for more information about your
            products. I'm looking for more information about your products. I'm
            looking for more information about your products. I'm looking for
            more information about your products.
          </div>
        </div>

        <div className={styles.chat_input_view}>
          <div className={styles.frame_18}>
            <div className={styles.frame_3}>
              <div className={`${styles.input_group} ${styles.mb_3} ${styles.chat_textfield_div}`}>
                <textarea
                  id="messageInput"
                  className={`${styles.form_control} ${styles.chat_textfield}`}
                  placeholder="Describe your idea..."
                ></textarea>
              </div>
              <svg
                className={styles.send}
                id="sendIcon"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <mask
                  id="mask0_492_88748"
                  style={{maskType: 'alpha'}}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="0"
                  width="32"
                  height="32"
                >
                  <rect width="32" height="32" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_492_88748)">
                  <path
                    d="M3.75293 26.9216V18.8897L14.7863 15.9999L3.75293 13.11V5.07812L29.6522 15.9999L3.75293 26.9216Z"
                    fill="#6C4ADE"
                  />
                </g>
              </svg>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
