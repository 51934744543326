import React from 'react';

import styles from './Questionaire.module.css';
import Navbar from '../Navbar';
import ChatWindow from '../ChatWindow';
import Editor from '../Editor'

interface QuestionaireProps{

}

interface QuestionaireState{

}



export  default class Questionaire extends React.Component<QuestionaireProps, QuestionaireState> {

    
    render(){
        return(
            <div className={styles.main_container}>
                <div>
                  <Navbar stepNumber={1}/>
                </div>
                <div className={styles.chat_frame}>
                   <ChatWindow/> 
                </div>
            </div>
        );
    }
}