import React, { useRef } from 'react';
import styles from './SignIn.module.css';
import Form from 'react-bootstrap/Form';
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { useNavigate } from 'react-router-dom';
import userEvent from '@testing-library/user-event';
import './bootstrap.min.css'

interface SignInProps {}

const SignIn: React.FC<SignInProps> = () => {

    const emailinputRef = useRef<HTMLInputElement>(null);
    const passwordinputRef = useRef<HTMLInputElement>(null);


    const navigate = useNavigate();

    const forgotPassword = () => {
        navigate('/ForgotPassword');
    }

    const signUp = () => {
        navigate('/SignUp');
    }

     
    const homePage = () =>{
        navigate('/');
    }


    const dahsboardPage = () => {
        if (emailinputRef.current && passwordinputRef.current) {
            const email = emailinputRef.current.value;
            const password = passwordinputRef.current.value;

            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('userEmail', email);
            
            if (email === "guest" && password === "password") {
                navigate('/Dashboard');
            } else {
                alert("Wrong username/password entered.");
            }
        }
    };
    

    const handleLogin = (username:string) => {
        // Perform login logic (e.g., authenticate against a backend)
    
        // If login is successful, store user information in localStorage
        localStorage.setItem('isLoggedIn', 'true');
        localStorage.setItem('username', username);
    
        // ... rest of your login logic
    };

    return (
        <>
    <div className={styles.sign_in}>
      <div className={styles.frame_299}>
        <div className={styles.frame_357}>
          <div className={styles.frame_2608484}>
            <div className={styles.frame_354}>
              <div className={styles.frame_2608483}>
                <div className={styles.frame_20}>
                  <img className={styles.ellipse_10} src="/images/SignIn/ellipse-100.png" />
                </div>
                <div className={styles.ai_gen} onClick={homePage}>Appefy</div>
              </div>
              <div className={styles.sign_in2}>Sign in</div>
            </div>
            <div className={styles.frame_2608492}>
              <div className={styles.no_account}>No account?</div>
              <div className={styles.sign_up_here}>
                <div className={styles.frame_2608493}>
                  <div className={styles.navlink} onClick={signUp}>Sign up here</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frame_360}>
            <div className={styles.frame_1}>
              <div className={styles.frame_2608491}>
                <div className={styles.input}>
                  <div className={styles.field}>
                    <div className={styles.input_content}>
                      <div className={styles.search} >
                        <input type="text" ref={emailinputRef} className={styles.input_content} placeholder='Email' aria-label="Email" required/>
                     </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frame_2608488}>
                  <div className={styles.frame_2608482}>
                    <div className={styles.input2}>
                      <div className={styles.field}>
                        <div className={styles.input_content}>
                          <div className={styles.search}><input type="password" ref={passwordinputRef} className={styles.input_content} placeholder='Password' aria-label="Password" required/></div>
                        </div>
                      </div>
                    </div>
                    <svg
                      className={styles.icon}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.08909 1.91058C2.76366 1.58514 2.23602 1.58514 1.91058 1.91058C1.58514 2.23602 1.58514 2.76366 1.91058 3.08909L4.33168 5.51019C3.02132 6.7087 2.2633 8.11499 1.89137 8.95039C1.59265 9.62136 1.59308 10.3795 1.89163 11.05C2.61608 12.6769 4.90951 16.6666 10.0001 16.6666C11.7431 16.6666 13.1716 16.1928 14.3212 15.4997L16.9106 18.0891C17.236 18.4145 17.7637 18.4145 18.0891 18.0891C18.4145 17.7637 18.4145 17.236 18.0891 16.9106L3.08909 1.91058ZM13.0973 14.2759L11.6931 12.8716C11.1969 13.1646 10.6179 13.3332 9.99984 13.3332C8.15889 13.3332 6.6665 11.8408 6.6665 9.99989C6.6665 9.38188 6.83511 8.80281 7.12817 8.30668L5.51164 6.69015C4.40448 7.6823 3.74411 8.88667 3.41395 9.62826C3.30756 9.86724 3.30756 10.1326 3.41418 10.372C4.075 11.8561 5.95411 14.9999 10.0001 14.9999C11.2316 14.9999 12.2521 14.7132 13.0973 14.2759ZM8.38952 9.56803C8.35273 9.70574 8.33317 9.85048 8.33317 9.99989C8.33317 10.9204 9.07936 11.6666 9.99984 11.6666C10.1493 11.6666 10.294 11.647 10.4317 11.6102L8.38952 9.56803Z"
                        fill="#84868E"
                      />
                      <path
                        d="M10.0001 3.33317C15.0908 3.33317 17.3841 7.32302 18.1084 8.94988C18.407 9.62054 18.407 10.3791 18.1084 11.0498C17.9787 11.3411 17.8047 11.6958 17.5801 12.0839C17.3496 12.4822 16.8398 12.6183 16.4415 12.3878C16.0431 12.1573 15.9071 11.6475 16.1376 11.2491C16.3289 10.9186 16.4766 10.6171 16.5858 10.3719C16.6923 10.1328 16.6923 9.86691 16.5858 9.62776C15.9251 8.14378 14.0462 4.99984 10.0001 4.99984L9.16652 4.99984C8.70628 4.99985 8.33318 4.62676 8.33317 4.16652C8.33317 3.70628 8.70626 3.33318 9.1665 3.33318L10.0001 3.33317Z"
                        fill="#84868E"
                      />
                    </svg>
                  </div>
                  <div className={styles.sign_up_here}>
                    <div className={styles.frame_2608493}>
                      <div className={styles.navlink} onClick={forgotPassword}>Forgot password?</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.button_panel}>
                <div className={styles.button_frame}>
                  <div className={styles.button_text} onClick={dahsboardPage}>Sign in</div>
                </div>
              </div>
            </div>
            <div className={styles.frame_2608477}>
              <div className={styles.line_54}></div>
              <div className={styles.or_continue_with}>Or continue with</div>
              <div className={styles.line_55}></div>
            </div>
            <div className={styles.frame_2608481}>
              <div className={styles.frame_3472}>
                <div className={styles.image_10}>
                  <img className={styles.image_102} src="/images/SignIn/image-101.png" />
                </div>
                <div className={styles.accept_all_cockies2}>Sign in With Google</div>
              </div>
              <div className={styles.frame_2608479}>
                <div className={styles.image_11}>
                  <img className={styles.image_112} src="/images/SignIn/image-111.png" />
                </div>
                <div className={styles.accept_all_cockies2}>Sign in With Outlook</div>
              </div>
              <div className={styles.frame_2608480}>
                <div className={styles.image_12}>
                  <img className={styles.image_122} src="/images/SignIn/image-121.png" />
                </div>
                <div className={styles.accept_all_cockies2}>Sign in With Facebook</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
    );
};

export default SignIn;
