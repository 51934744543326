import React from "react";

import "./Home.css";

import styles from './home.module.css';
import { useNavigate } from 'react-router-dom';


interface HomeProps {
 
}

interface HomeState {}



const Home:React.FC<HomeProps> = () => {

    const navigate = useNavigate();

    const qnapage = () =>{
      navigate('/qna')
    }


    return (
        <div className={styles.landing}>
        <svg
          className={styles.background}
          width="100vw"
          height="100vh"
          viewBox="0 0 1432 1061"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_593_50402)">
            <rect x="-3" width="1443" height="1024" fill="white" />
            <g filter="url(#filter0_f_593_50402)">
              <path
                d="M-148.677 737.339C-487.318 251.328 464.72 684.154 828.042 574.745C1188.55 466.183 1178.68 -39.1947 1689.1 185.949C2199.52 411.092 2201.85 528.902 1759.13 333.487C1316.4 138.072 1249 602.624 886.978 711.101C526.07 819.245 -78.6477 884.878 -78.6477 884.878C-78.6477 884.878 189.963 1223.35 -148.677 737.339Z"
                fill="url(#paint0_linear_593_50402)"
              />
            </g>
          </g>
          <defs>
            <filter
              id="filter0_f_593_50402"
              x="-520.317"
              y="-171.199"
              width="2902.37"
              height="1470.76"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="150"
                result="effect1_foregroundBlur_593_50402"
              />
            </filter>
            <linearGradient
              id="paint0_linear_593_50402"
              x1="1469.19"
              y1="103.722"
              x2="744.163"
              y2="1283.27"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#985FFF" />
              <stop offset="0.509265" stop-color="#FFA5E1" />
              <stop offset="0.729167" stop-color="#8EAEFF" />
              <stop offset="1" stop-color="#9C7EFF" stop-opacity="0.56" />
            </linearGradient>
            <clipPath id="clip0_593_50402">
              <rect width="1440" height="1061" fill="white" />
            </clipPath>
          </defs>
        </svg>

        <div className={styles.frame_266}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.heading_and_supporting_text}>
                <div className={styles.heading_and_subheading}>
                  <div className={styles.subheading}>Features</div>
                  <div className={styles.heading}>Discover the Advantages</div>
                </div>
                <div className={styles.supporting_text}>
                  Elevate Your Website&#039;s Impact with Exclusive Benefits! From
                  professionals streamlining their workflows to enthusiasts
                  enjoying seamless experiences, our apps have garnered widespread
                  adoption.
                </div>
              </div>
            </div>
          </div>
          <div className={styles.benefits}>
            <div className={styles.benefit}>
              <div className={styles.content2}>
                <div className={styles.icon_and_text}>
                  <div className={styles.featured_icon}>
                    <svg
                      className={styles.zap}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13 2L3 14H12L11 22L21 10H12L13 2Z"
                        stroke="#6C4ADE"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className={styles.heading_and_supporting_text2}>
                    <div className={styles.heading2}>Intelligent Design Assistance</div>
                    <div className={styles.supporting_text2}>
                      Experience the power of AI-driven design suggestions. Our
                      web app&#039;s AI analyzes your content and offers
                      intelligent design recommendations, ensuring your website
                      looks polished and professional without the need for
                      extensive manual adjustments.
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.content3}>
                <div className={styles.rectangle_51}></div>
                <img className={styles.rectangle_55} src="/images/home/rectangle-550.png" /><img
                  className={styles.rectangle_53}
                  src="/images/home/rectangle-530.png"
                /><img className={styles.rectangle_54} src="/images/home/rectangle-540.png" />
                <div className={styles.messsage}>
                  <div className={styles.frame_17}>
                    <div className={styles.message}>
                      <div className={styles.frame_7}>
                        <div className={styles.rectangle_8}></div>
                        <div className={styles.rectangle_10}></div>
                        <div className={styles.rectangle_11}></div>
                      </div>
                      <div className={styles.frame_6}>
                        <div className={styles.frame_5}>
                          <div className={styles.generate_corporative_website}>
                            Generate corporative website
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <svg
                    className={styles.frame_8}
                    width="46"
                    height="61"
                    viewBox="0 0 46 61"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M24.3323 23.3012C23.2812 11.2866 12.6894 2.39899 0.674791 3.45013L3.05386 30.643L24.8081 28.7397L24.3323 23.3012Z"
                      fill="#6C4ADE"
                    />
                    <rect
                      width="21.8374"
                      height="3.07664"
                      transform="matrix(-0.996195 0.0871557 0.0871557 0.996195 24.808 28.7383)"
                      fill="#6C4ADE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.32191 33.7074L25.0762 31.8041L25.552 37.2427C25.6137 37.9473 25.6411 38.6471 25.6358 39.3402C25.7236 39.6045 25.7841 39.9045 25.8137 40.2432C26.5178 48.2914 34.4695 54.5123 45.0265 56.2697C42.1311 57.2923 38.9606 57.9902 35.6123 58.2832C28.4901 58.9063 22.6343 57.8345 18.349 55.4306C14.9554 58.4676 10.5868 60.4728 5.70097 60.9002L3.32191 33.7074Z"
                      fill="#6C4ADE"
                    />
                  </svg>
                </div>
                <div className={styles.messsage2}>
                  <div className={styles.frame_172}>
                    <div className={styles.message2}>
                      <div className={styles.frame_7}>
                        <div className={styles.rectangle_8}></div>
                        <div className={styles.rectangle_10}></div>
                        <div className={styles.rectangle_11}></div>
                      </div>
                      <div className={styles.frame_6}>
                        <div className={styles.frame_5}>
                          <div className={styles.make_it_more_stylish}>
                            Make it more stylish
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <svg
                    className={styles.frame_82}
                    width="50"
                    height="65"
                    viewBox="0 0 50 65"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M28.311 26.4479C30.6122 14.609 22.8804 3.14619 11.0416 0.84495L5.83309 27.6402L27.2693 31.8069L28.311 26.4479Z"
                      fill="#6C4ADE"
                    />
                    <rect
                      width="21.8374"
                      height="3.07664"
                      transform="matrix(-0.981627 -0.190809 -0.190809 0.981627 27.2692 31.8086)"
                      fill="#6C4ADE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M5.24594 30.6627L26.6821 34.8295L25.6404 40.1885C25.5055 40.8829 25.339 41.563 25.1428 42.2279C25.1544 42.5061 25.1298 42.8112 25.0649 43.1449C23.5234 51.0754 29.4523 59.2472 39.1159 63.8464C36.0508 64.0312 32.8108 63.8282 29.5114 63.1869C22.4934 61.8227 17.1599 59.1784 13.7031 55.6865C9.60397 57.6704 4.85182 58.3938 0.0374754 57.4579L5.24594 30.6627Z"
                      fill="#6C4ADE"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className={styles.benefit2}>
              <div className={styles.img}>
                <div className={styles.content4}>
                  <div className={styles.rectangle_512}></div>
                </div>
                <div className={styles.created}>
                  <div className={styles.header}>
                    <div className={styles.action}>
                      <div className={styles.frame_22}>
                        <svg
                          className={styles.eye}
                          width="7"
                          height="7"
                          viewBox="0 0 7 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_593_50467)">
                            <path
                              d="M0.845215 3.52092C0.845215 3.52092 1.89474 1.42188 3.73141 1.42188C5.56807 1.42188 6.6176 3.52092 6.6176 3.52092C6.6176 3.52092 5.56807 5.61997 3.73141 5.61997C1.89474 5.61997 0.845215 3.52092 0.845215 3.52092Z"
                              stroke="#101828"
                              stroke-width="0.590357"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.73141 4.30807C4.16613 4.30807 4.51855 3.95565 4.51855 3.52092C4.51855 3.0862 4.16613 2.73378 3.73141 2.73378C3.29668 2.73378 2.94426 3.0862 2.94426 3.52092C2.94426 3.95565 3.29668 4.30807 3.73141 4.30807Z"
                              stroke="#101828"
                              stroke-width="0.590357"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_593_50467">
                              <rect
                                width="6.29714"
                                height="6.29714"
                                fill="white"
                                transform="translate(0.582886 0.371094)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        <div className={styles.preview}>Preview</div>
                      </div>
                      <div className={styles.frame_24}>
                        <svg
                          className={styles.download}
                          width="8"
                          height="7"
                          viewBox="0 0 8 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_593_50470)">
                            <path
                              d="M6.28151 4.30873V5.35825C6.28151 5.49743 6.22622 5.6309 6.12781 5.72931C6.0294 5.82773 5.89593 5.88301 5.75675 5.88301H2.08342C1.94424 5.88301 1.81077 5.82773 1.71235 5.72931C1.61394 5.6309 1.55865 5.49743 1.55865 5.35825V4.30873M2.60818 2.99682L3.92008 4.30873M3.92008 4.30873L5.23199 2.99682M3.92008 4.30873V1.16016"
                              stroke="#101828"
                              stroke-width="0.590357"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_593_50470">
                              <rect
                                width="6.29714"
                                height="6.29714"
                                fill="white"
                                transform="translate(0.771484 0.371094)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        <div className={styles.save}>Save</div>
                      </div>
                      <div className={styles.frame_23}>
                        <div className={styles.publish}>Publish</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.button_group}>
                    <div className={styles.button_group_base}>
                      <div className={styles.text}>Desktop</div>
                    </div>
                    <div className={styles.button_group_base2}>
                      <div className={styles.top_line}></div>
                      <div className={styles.content5}>
                        <div className={styles.text}>Mobile</div>
                      </div>
                      <div className={styles.bottom_line}></div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.button_group_base3}>
                      <div className={styles.text}>Tablet</div>
                    </div>
                  </div>
                  <div className={styles.text2}>Hover content to see editing options</div>
                  <div className={styles.chat}>
                    <div className={styles.frame_27}>
                      <div className={styles.frame_28}>
                        <div className={styles.ellipse_2}></div>
                        <div className={styles.ai_builder}>Appefy.AI</div>
                      </div>
                      <div className={styles.remix_icons_line_system_question_line}>
                        <svg
                          className={styles.group}
                          width="9.445713996887207"
                          height="9.445713996887207"
                        ></svg>
                      </div>
                    </div>
                    <div className={styles.frame_19}>
                      <div className={styles.frame_43}>
                        <div className={styles.message_bubble}>
                          <div className={styles.frame_59}>
                            <svg
                              className={styles.frame_83}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                            <div className={styles.frame_62}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  Hi! How can I help you today?
                                </div>
                              </div>
                            </div>
                            <div className={styles.frame_72}>
                              <div className={styles.rectangle_84}></div>
                              <div className={styles.rectangle_103}></div>
                              <div className={styles.rectangle_114}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_44}>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_61}>
                            <div className={styles.frame_73}>
                              <div className={styles.rectangle_85}></div>
                              <div className={styles.rectangle_104}></div>
                              <div className={styles.rectangle_115}></div>
                            </div>
                            <div className={styles.frame_63}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  Website for karate school
                                </div>
                              </div>
                            </div>
                            <svg
                              className={styles.frame_9}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_45}>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_60}>
                            <svg
                              className={styles.frame_92}
                              width="10.232856750488281"
                              height="57.44571304321289"
                            ></svg>
                            <div className={styles.frame_64}>
                              <div className={styles.frame_53}>
                                <div className={styles.write_your_message_here_3}>
                                  That sounds great! I found a couple colour
                                  palettes that will look great with karate
                                  school.<br /><br />Please choose one from the
                                  following options:
                                </div>
                              </div>
                            </div>
                            <div className={styles.frame_72}>
                              <div className={styles.rectangle_84}></div>
                              <div className={styles.rectangle_103}></div>
                              <div className={styles.rectangle_114}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_14}>
                        <div className={styles.frame_71}>
                          <div className={styles.frame_11}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_46}></div>
                              <div className={styles.frame_47}></div>
                              <div className={styles.frame_48}></div>
                              <div className={styles.frame_49}></div>
                              <div className={styles.frame_50}></div>
                            </div>
                          </div>
                          <div className={styles.frame_173}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_462}></div>
                              <div className={styles.frame_472}></div>
                              <div className={styles.frame_482}></div>
                              <div className={styles.frame_492}></div>
                              <div className={styles.frame_502}></div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_722}>
                          <div className={styles.frame_12}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_463}></div>
                              <div className={styles.frame_473}></div>
                              <div className={styles.frame_483}></div>
                              <div className={styles.frame_493}></div>
                              <div className={styles.frame_503}></div>
                            </div>
                          </div>
                          <div className={styles.frame_16}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_464}></div>
                              <div className={styles.frame_474}></div>
                              <div className={styles.frame_484}></div>
                              <div className={styles.frame_494}></div>
                              <div className={styles.frame_504}></div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_15}>
                          <div className={styles.frame_152}>
                            <svg
                              className={styles.replay}
                              width="9.445713996887207"
                              height="9.445713996887207"
                            ></svg>
                            <div className={styles.refresh}>Refresh</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_465}>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_61}>
                            <div className={styles.frame_73}>
                              <div className={styles.rectangle_85}></div>
                              <div className={styles.rectangle_104}></div>
                              <div className={styles.rectangle_115}></div>
                            </div>
                            <div className={styles.frame_63}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  I would like to choose this color palette:
                                </div>
                              </div>
                            </div>
                            <svg
                              className={styles.frame_93}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                          </div>
                        </div>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_68}>
                            <div className={styles.frame_10}>
                              <div className={styles.rectangle_87}></div>
                              <div className={styles.rectangle_105}></div>
                              <div className={styles.rectangle_117}></div>
                            </div>
                            <div className={styles.frame_65}>
                              <div className={styles.frame_523}>
                                <div className={styles.frame_463}></div>
                                <div className={styles.frame_473}></div>
                                <div className={styles.frame_483}></div>
                                <div className={styles.frame_493}></div>
                                <div className={styles.frame_503}></div>
                              </div>
                            </div>
                            <svg
                              className={styles.frame_94}
                              width="10.232856750488281"
                              height="34.63428497314453"
                            ></svg>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_485}>
                        <div className={styles.message_bubble}>
                          <div className={styles.frame_59}>
                            <svg
                              className={styles.frame_84}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                            <div className={styles.frame_66}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  Great choice!
                                </div>
                              </div>
                            </div>
                            <div className={styles.frame_72}>
                              <div className={styles.rectangle_85}></div>
                              <div className={styles.rectangle_104}></div>
                              <div className={styles.rectangle_115}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frame_18}>
                      <div className={styles.frame_3}>
                        <div className={styles.frame_4}>
                          <div className={styles.line_1}></div>
                          <div className={styles.describe_your_idea_in_once_sentence}>
                            Describe your idea in once sentence...
                          </div>
                        </div>
                        <svg
                          className={styles.send}
                          width="12.594285011291504"
                          height="12.594285011291504"
                        ></svg>
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles._1697770914142_nudge_20_desktop_3026_b_438275_d_4_bf_98338506_b_05_a_36_e_2_apng_1}
                    src="/images/home/_1697770914142-nudge-20-desktop-3026-b-438275-d-4-bf-98338506-b-05-a-36-e-2-apng-10.png"
                  />
                </div>
                <div className={styles.created2}>
                  <div className={styles.header}>
                    <div className={styles.action}>
                      <div className={styles.frame_22}>
                        <svg
                          className={styles.eye2}
                          width="7"
                          height="7"
                          viewBox="0 0 7 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_593_50584)">
                            <path
                              d="M0.845215 3.52092C0.845215 3.52092 1.89474 1.42188 3.73141 1.42188C5.56807 1.42188 6.6176 3.52092 6.6176 3.52092C6.6176 3.52092 5.56807 5.61997 3.73141 5.61997C1.89474 5.61997 0.845215 3.52092 0.845215 3.52092Z"
                              stroke="#101828"
                              stroke-width="0.590357"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M3.73141 4.30807C4.16613 4.30807 4.51855 3.95565 4.51855 3.52092C4.51855 3.0862 4.16613 2.73378 3.73141 2.73378C3.29668 2.73378 2.94426 3.0862 2.94426 3.52092C2.94426 3.95565 3.29668 4.30807 3.73141 4.30807Z"
                              stroke="#101828"
                              stroke-width="0.590357"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_593_50584">
                              <rect
                                width="6.29714"
                                height="6.29714"
                                fill="white"
                                transform="translate(0.582886 0.371094)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        <div className={styles.preview}>Preview</div>
                      </div>
                      <div className={styles.frame_24}>
                        <svg
                          className={styles.download2}
                          width="8"
                          height="7"
                          viewBox="0 0 8 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_593_50587)">
                            <path
                              d="M6.28145 4.30873V5.35825C6.28145 5.49743 6.22616 5.6309 6.12775 5.72931C6.02934 5.82773 5.89586 5.88301 5.75669 5.88301H2.08336C1.94418 5.88301 1.8107 5.82773 1.71229 5.72931C1.61388 5.6309 1.55859 5.49743 1.55859 5.35825V4.30873M2.60812 2.99682L3.92002 4.30873M3.92002 4.30873L5.23193 2.99682M3.92002 4.30873V1.16016"
                              stroke="#101828"
                              stroke-width="0.590357"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_593_50587">
                              <rect
                                width="6.29714"
                                height="6.29714"
                                fill="white"
                                transform="translate(0.771484 0.371094)"
                              />
                            </clipPath>
                          </defs>
                        </svg>

                        <div className={styles.save}>Save</div>
                      </div>
                      <div className={styles.frame_23}>
                        <div className={styles.publish}>Publish</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.button_group}>
                    <div className={styles.button_group_base}>
                      <div className={styles.text}>Desktop</div>
                    </div>
                    <div className={styles.button_group_base2}>
                      <div className={styles.top_line}></div>
                      <div className={styles.content5}>
                        <div className={styles.text}>Mobile</div>
                      </div>
                      <div className={styles.bottom_line}></div>
                    </div>
                    <div className={styles.divider}></div>
                    <div className={styles.button_group_base3}>
                      <div className={styles.text}>Tablet</div>
                    </div>
                  </div>
                  <div className={styles.text2}>Hover content to see editing options</div>
                  <div className={styles.chat}>
                    <div className={styles.frame_27}>
                      <div className={styles.frame_28}>
                        <div className={styles.ellipse_2}></div>
                        <div className={styles.ai_builder}>AI BUILDER</div>
                      </div>
                      <div className={styles.remix_icons_line_system_question_line}>
                        <svg
                          className={styles.group2}
                          width="9.445713996887207"
                          height="9.445713996887207"
                        ></svg>
                      </div>
                    </div>
                    <div className={styles.frame_19}>
                      <div className={styles.frame_43}>
                        <div className={styles.message_bubble}>
                          <div className={styles.frame_59}>
                            <svg
                              className={styles.frame_85}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                            <div className={styles.frame_62}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  Hi! How can I help you today?
                                </div>
                              </div>
                            </div>
                            <div className={styles.frame_72}>
                              <div className={styles.rectangle_84}></div>
                              <div className={styles.rectangle_103}></div>
                              <div className={styles.rectangle_114}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_44}>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_61}>
                            <div className={styles.frame_73}>
                              <div className={styles.rectangle_85}></div>
                              <div className={styles.rectangle_104}></div>
                              <div className={styles.rectangle_115}></div>
                            </div>
                            <div className={styles.frame_63}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  Website for karate school
                                </div>
                              </div>
                            </div>
                            <svg
                              className={styles.frame_95}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_45}>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_60}>
                            <svg
                              className={styles.frame_96}
                              width="10.232856750488281"
                              height="57.44571304321289"
                            ></svg>
                            <div className={styles.frame_64}>
                              <div className={styles.frame_53}>
                                <div className={styles.write_your_message_here_3}>
                                  That sounds great! I found a couple colour
                                  palettes that will look great with karate
                                  school.<br /><br />Please choose one from the
                                  following options:
                                </div>
                              </div>
                            </div>
                            <div className={styles.frame_72}>
                              <div className={styles.rectangle_84}></div>
                              <div className={styles.rectangle_103}></div>
                              <div className={styles.rectangle_114}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_14}>
                        <div className={styles.frame_71}>
                          <div className={styles.frame_11}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_46}></div>
                              <div className={styles.frame_47}></div>
                              <div className={styles.frame_48}></div>
                              <div className={styles.frame_49}></div>
                              <div className={styles.frame_50}></div>
                            </div>
                          </div>
                          <div className={styles.frame_173}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_462}></div>
                              <div className={styles.frame_472}></div>
                              <div className={styles.frame_482}></div>
                              <div className={styles.frame_492}></div>
                              <div className={styles.frame_502}></div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_722}>
                          <div className={styles.frame_12}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_463}></div>
                              <div className={styles.frame_473}></div>
                              <div className={styles.frame_483}></div>
                              <div className={styles.frame_493}></div>
                              <div className={styles.frame_503}></div>
                            </div>
                          </div>
                          <div className={styles.frame_16}>
                            <div className={styles.frame_522}>
                              <div className={styles.frame_464}></div>
                              <div className={styles.frame_474}></div>
                              <div className={styles.frame_484}></div>
                              <div className={styles.frame_494}></div>
                              <div className={styles.frame_504}></div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_15}>
                          <div className={styles.frame_152}>
                            <svg
                              className={styles.replay3}
                              width="9.445713996887207"
                              height="9.445713996887207"
                            ></svg>
                            <div className={styles.refresh}>Refresh</div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_465}>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_61}>
                            <div className={styles.frame_73}>
                              <div className={styles.rectangle_85}></div>
                              <div className={styles.rectangle_104}></div>
                              <div className={styles.rectangle_115}></div>
                            </div>
                            <div className={styles.frame_63}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  I would like to choose this color palette:
                                </div>
                              </div>
                            </div>
                            <svg
                              className={styles.frame_97}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                          </div>
                        </div>
                        <div className={styles.message_bubble2}>
                          <div className={styles.frame_68}>
                            <div className={styles.frame_10}>
                              <div className={styles.rectangle_87}></div>
                              <div className={styles.rectangle_105}></div>
                              <div className={styles.rectangle_117}></div>
                            </div>
                            <div className={styles.frame_65}>
                              <div className={styles.frame_523}>
                                <div className={styles.frame_463}></div>
                                <div className={styles.frame_473}></div>
                                <div className={styles.frame_483}></div>
                                <div className={styles.frame_493}></div>
                                <div className={styles.frame_503}></div>
                              </div>
                            </div>
                            <svg
                              className={styles.frame_98}
                              width="10.232856750488281"
                              height="34.63428497314453"
                            ></svg>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_485}>
                        <div className={styles.message_bubble}>
                          <div className={styles.frame_59}>
                            <svg
                              className={styles.frame_86}
                              width="10.232856750488281"
                              height="17.44571304321289"
                            ></svg>
                            <div className={styles.frame_66}>
                              <div className={styles.frame_52}>
                                <div className={styles.write_your_message_here_3}>
                                  Great choice!
                                </div>
                              </div>
                            </div>
                            <div className={styles.frame_72}>
                              <div className={styles.rectangle_85}></div>
                              <div className={styles.rectangle_104}></div>
                              <div className={styles.rectangle_115}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frame_18}>
                      <div className={styles.frame_3}>
                        <div className={styles.frame_4}>
                          <div className={styles.line_1}></div>
                          <div className={styles.describe_your_idea_in_once_sentence}>
                            Describe your idea in once sentence...
                          </div>
                        </div>
                        <svg
                          className={styles.send3}
                          width="12.594285011291504"
                          height="12.594285011291504"
                        ></svg>
                      </div>
                    </div>
                  </div>
                  <img
                    className={styles._1697770914158_nudge_20_mobilebfea_2_cd_47_dc_343808_e_31555_d_9_ed_1_ce_5_dpng_1}
                    src="/images/home/_1697770914158-nudge-20-mobilebfea-2-cd-47-dc-343808-e-31555-d-9-ed-1-ce-5-dpng-10.png"
                  />
                </div>
                <div className={styles.button_group2}>
                  <div className={styles.button_group_base4}>
                    <svg
                      className={styles.laptop_mac}
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_593_50699"
                        style={{maskType: 'alpha'}}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="25"
                        height="24"
                      >
                        <rect
                          x="0.743042"
                          width="23.4288"
                          height="23.4288"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_593_50699)">
                        <path
                          d="M2.82605 19.1686C2.38926 19.1686 2.01411 19.0118 1.70061 18.6983C1.3871 18.3848 1.23035 18.0097 1.23035 17.5729H4.94745C4.45434 17.5729 4.03695 17.402 3.69528 17.0604C3.35361 16.7187 3.18277 16.3013 3.18277 15.8082V5.18265C3.18277 4.68954 3.35361 4.27215 3.69528 3.93047C4.03695 3.5888 4.45434 3.41797 4.94745 3.41797H19.9659C20.459 3.41797 20.8764 3.5888 21.2181 3.93047C21.5597 4.27215 21.7306 4.68954 21.7306 5.18265V15.8082C21.7306 16.3013 21.5597 16.7187 21.2181 17.0604C20.8764 17.402 20.459 17.5729 19.9659 17.5729H23.683C23.683 18.0097 23.5262 18.3848 23.2127 18.6983C22.8992 19.0118 22.524 19.1686 22.0873 19.1686H2.82605ZM12.4566 18.4364C12.6769 18.4364 12.8634 18.3601 13.0161 18.2074C13.1688 18.0547 13.2451 17.8682 13.2451 17.648C13.2451 17.4277 13.1688 17.2412 13.0161 17.0885C12.8634 16.9358 12.6769 16.8595 12.4566 16.8595C12.2364 16.8595 12.0499 16.9358 11.8972 17.0885C11.7445 17.2412 11.6682 17.4277 11.6682 17.648C11.6682 17.8682 11.7445 18.0547 11.8972 18.2074C12.0499 18.3601 12.2364 18.4364 12.4566 18.4364ZM4.94743 16.1086H19.9659C20.041 16.1086 20.1098 16.0773 20.1724 16.0147C20.235 15.9522 20.2663 15.8833 20.2663 15.8082V5.18265C20.2663 5.10755 20.235 5.0387 20.1724 4.97611C20.1098 4.91353 20.041 4.88225 19.9659 4.88225H4.94743C4.87233 4.88225 4.80348 4.91353 4.74089 4.97611C4.67832 5.0387 4.64703 5.10755 4.64703 5.18265V15.8082C4.64703 15.8833 4.67832 15.9522 4.74089 16.0147C4.80348 16.0773 4.87233 16.1086 4.94743 16.1086Z"
                          fill="#344054"
                        />
                      </g>
                    </svg>

                    <div className={styles.text3}>Desktop</div>
                  </div>
                  <div className={styles.button_group_base2}>
                    <div className={styles.top_line2}></div>
                    <div className={styles.content6}>
                      <svg
                        className={styles.smartphone}
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_593_50706"
                          style={{maskType: 'alpha'}}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect
                            x="0.029541"
                            width="23.4288"
                            height="23.4288"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_593_50706)">
                          <path
                            d="M7.16422 21.965C6.6711 21.965 6.25371 21.7942 5.91204 21.4525C5.57037 21.1108 5.39954 20.6935 5.39954 20.2003V3.22952C5.39954 2.73641 5.57037 2.31902 5.91204 1.97735C6.25371 1.63568 6.6711 1.46484 7.16422 1.46484H16.3254C16.8185 1.46484 17.2359 1.63568 17.5776 1.97735C17.9193 2.31902 18.0901 2.73641 18.0901 3.22952V20.2003C18.0901 20.6935 17.9193 21.1109 17.5776 21.4525C17.2359 21.7942 16.8185 21.965 16.3254 21.965L7.16422 21.965ZM6.86381 19.2805V20.2004C6.86381 20.2755 6.8951 20.3443 6.95768 20.4069C7.02027 20.4695 7.08911 20.5008 7.16422 20.5008H16.3254C16.4005 20.5008 16.4694 20.4695 16.532 20.4069C16.5945 20.3443 16.6258 20.2755 16.6258 20.2004V19.2805H6.86381ZM6.86381 17.8162H16.6258V5.61368H6.86381V17.8162ZM6.86381 4.14942H16.6258V3.22955C16.6258 3.15445 16.5945 3.0856 16.532 3.02301C16.4694 2.96043 16.4005 2.92915 16.3254 2.92915H7.16422C7.08911 2.92915 7.02027 2.96043 6.95768 3.02301C6.8951 3.0856 6.86381 3.15445 6.86381 3.22955V4.14942Z"
                            fill="#344054"
                          />
                        </g>
                      </svg>

                      <div className={styles.text3}>Mobile</div>
                    </div>
                    <div className={styles.bottom_line2}></div>
                  </div>
                  <div className={styles.divider2}></div>
                  <div className={styles.button_group_base5}>
                    <svg
                      className={styles.tablet_mac}
                      width="25"
                      height="24"
                      viewBox="0 0 25 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_593_50743"
                        style={{maskType: 'alpha'}}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="25"
                        height="24"
                      >
                        <rect
                          x="0.801758"
                          width="23.4288"
                          height="23.4288"
                          fill="#D9D9D9"
                        />
                      </mask>
                      <g mask="url(#mask0_593_50743)">
                        <path
                          d="M12.5153 19.7874C12.7544 19.7874 12.9581 19.7032 13.1264 19.5348C13.2947 19.3665 13.3789 19.1628 13.3789 18.9238C13.3789 18.6848 13.2947 18.4811 13.1264 18.3127C12.9581 18.1444 12.7544 18.0602 12.5153 18.0602C12.2763 18.0602 12.0726 18.1444 11.9043 18.3127C11.736 18.4811 11.6518 18.6848 11.6518 18.9238C11.6518 19.1628 11.736 19.3665 11.9043 19.5348C12.0726 19.7032 12.2763 19.7874 12.5153 19.7874ZM5.98233 21.965C5.49548 21.965 5.07966 21.7926 4.73487 21.4478C4.39006 21.103 4.21765 20.6872 4.21765 20.2003V3.22952C4.21765 2.74268 4.39006 2.32685 4.73487 1.98206C5.07966 1.63725 5.49548 1.46484 5.98233 1.46484H19.0484C19.5352 1.46484 19.951 1.63725 20.2958 1.98206C20.6406 2.32685 20.813 2.74268 20.813 3.22952V20.2003C20.813 20.6872 20.6406 21.103 20.2958 21.4478C19.951 21.7926 19.5352 21.9651 19.0484 21.9651L5.98233 21.965ZM5.68193 17.3469V20.2003C5.68193 20.288 5.71009 20.3599 5.76642 20.4163C5.82275 20.4726 5.89472 20.5007 5.98233 20.5007H19.0484C19.136 20.5007 19.2079 20.4726 19.2643 20.4163C19.3206 20.3599 19.3488 20.288 19.3488 20.2003V17.3469H5.68193ZM5.68193 15.8826H19.3488V5.61365H5.68193V15.8826ZM5.68193 4.1494H19.3488V3.22952C19.3488 3.14191 19.3206 3.06994 19.2643 3.01361C19.2079 2.95729 19.136 2.92912 19.0484 2.92912H5.98233C5.89472 2.92912 5.82275 2.95729 5.76642 3.01361C5.71009 3.06994 5.68193 3.14191 5.68193 3.22952V4.1494Z"
                          fill="#4D5769"
                        />
                      </g>
                    </svg>

                    <div className={styles.text3}>Tablet</div>
                  </div>
                </div>
              </div>
              <div className={styles.content7}>
                <div className={styles.icon_and_text}>
                  <div className={styles.featured_icon}>
                    <svg
                      className={styles.smartphone3}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_593_50750"
                        style={{maskType: 'alpha'}}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="24"
                        height="24"
                      >
                        <rect width="24" height="24" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_593_50750)">
                        <path
                          d="M7.30849 22.4999C6.80336 22.4999 6.37579 22.3249 6.02579 21.9749C5.67579 21.6249 5.50079 21.1974 5.50079 20.6923V3.3077C5.50079 2.80257 5.67579 2.375 6.02579 2.025C6.37579 1.675 6.80336 1.5 7.30849 1.5H16.693C17.1982 1.5 17.6257 1.675 17.9757 2.025C18.3257 2.375 18.5007 2.80257 18.5007 3.3077V20.6923C18.5007 21.1974 18.3257 21.625 17.9757 21.975C17.6257 22.325 17.1982 22.5 16.693 22.5L7.30849 22.4999ZM7.00077 19.75V20.6923C7.00077 20.7692 7.03282 20.8397 7.09692 20.9039C7.16104 20.968 7.23156 21 7.30849 21H16.693C16.77 21 16.8405 20.968 16.9046 20.9039C16.9687 20.8397 17.0008 20.7692 17.0008 20.6923V19.75H7.00077ZM7.00077 18.25H17.0008V5.74998H7.00077V18.25ZM7.00077 4.25003H17.0008V3.30773C17.0008 3.23079 16.9687 3.16027 16.9046 3.09615C16.8405 3.03205 16.77 3 16.693 3H7.30849C7.23156 3 7.16104 3.03205 7.09692 3.09615C7.03282 3.16027 7.00077 3.23079 7.00077 3.30773V4.25003Z"
                          fill="#6C4ADE"
                        />
                      </g>
                    </svg>
                  </div>
                  <div className={styles.heading_and_supporting_text2}>
                    <div className={styles.heading2}>Responsive Design Across Devices</div>
                    <div className={styles.supporting_text2}>
                      Enjoy the benefits of responsive design without the hassle.
                      Our web app&#039;s AI automatically adapts your website to
                      different devices, ensuring a consistent and user-friendly
                      experience on desktops, tablets, and smartphones.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.benefit3}>
              <div className={styles.content2}>
                <div className={styles.icon_and_text}>
                  <div className={styles.featured_icon}>
                    <svg
                      className={styles.bar_chart_2}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 20V10M12 20V4M6 20V14"
                        stroke="#6C4ADE"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className={styles.heading_and_supporting_text2}>
                    <div className={styles.heading2}>Data_Driven Insights</div>
                    <div className={styles.supporting_text2}>
                      Gain valuable insights into your website&#039;s performance
                      with AI-driven analytics. Track user behavior, identify
                      trends, and receive recommendations for improvements,
                      empowering you to make informed decisions for the ongoing
                      success of your site.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.baner}>
          <div className={styles.txt}>
            <img className={styles._2_6} src="/images/home/_2-60.png" /><img
              className={styles._2_7}
              src="/images/home/_2-70.png"
            /><img className={styles._2_10} src="/images/home/_2-100.png" /><img
              className={styles._2_8}
              src="/images/home/_2-80.png"
            /><img className={styles._2_9} src="/images/home/_2-90.png" />
            <div className={styles.txt2}>
              <div className={styles.txt3}>
                <div className={styles.start_your_30_day_free_trial}>
                  Start your 30-day free trial
                </div>
                <div className={styles.get_up_and_running_in_less_than_5_minutes}>
                  Get up and running in less than 5 minutes.
                </div>
              </div>
              <div className={styles.button}>
                <div className={styles.get_started}>Get Started</div>
              </div>
            </div>
            <div className={styles.frame_128}>
              <div className={styles.frame_125}>
                <div className={styles.frame_163}>
                  <div className={styles.frame_124}>
                    <div className={styles.pro}>PRO</div>
                    <div className={styles.frame_122}>
                      <div className={styles._10}>$10</div>
                      <div className={styles._10}>/</div>
                      <div className={styles.month}>month</div>
                    </div>
                  </div>
                  <div className={styles.frame_121}>
                    <div className={styles.popular}>Popular</div>
                  </div>
                </div>
                <div className={styles.frame_126}>
                  <div className={styles.enroll}>Enroll</div>
                </div>
                <div className={styles.frame_102}>
                  <div className={styles.frame_103}>
                    <svg
                      className={styles.component_1_check}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                        fill="#4541E5"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                        fill="#4541E5"
                      />
                    </svg>

                    <div className={styles.one_website}>One website</div>
                  </div>
                  <div className={styles.frame_142}>
                    <svg
                      className={styles.component_1_check2}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                        fill="#4541E5"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                        fill="#4541E5"
                      />
                    </svg>

                    <div className={styles.interest_list}>Interest List</div>
                  </div>
                  <div className={styles.frame_112}>
                    <svg className={styles.component_1_check3} width="24" height="24"></svg>
                    <div className={styles.seo_management}>SEO management</div>
                  </div>
                  <div className={styles.frame_174}>
                    <svg className={styles.close_cr_fr} width="24" height="24"></svg>
                    <div className={styles.settings}>Settings</div>
                  </div>
                  <div className={styles.frame_192}>
                    <svg className={styles.close_cr_fr2} width="24" height="24"></svg>
                    <div className={styles.billing}>Billing</div>
                  </div>
                  <div className={styles.frame_122}>
                    <svg className={styles.close_cr_fr3} width="24" height="24"></svg>
                    <div className={styles.integrations}>Integrations</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frame_130}>
          <div className={styles.frame_1302}>
            <div className={styles.frame_125}>
              <div className={styles.frame_124}>
                <div className={styles.basic}>Basic</div>
                <div className={styles.frame_122}>
                  <div className={styles.free}>Free</div>
                  <div className={styles.forever}>forever</div>
                </div>
              </div>
              <div className={styles.frame_126}>
                <div className={styles.enroll2}>Enroll</div>
              </div>
              <div className={styles.frame_102}>
                <div className={styles.frame_103}>
                  <svg
                    className={styles.component_1_check4}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.one_website2}>One website</div>
                </div>
                <div className={styles.frame_142}>
                  <svg
                    className={styles.component_1_check5}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.interest_list2}>Interest List</div>
                </div>
                <div className={styles.frame_112}>
                  <svg
                    className={styles.component_1_check6}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.seo_management2}>SEO management</div>
                </div>
                <div className={styles.frame_174}>
                  <svg
                    className={styles.component_1_check7}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.settings2}>Settings</div>
                </div>
                <div className={styles.frame_192}>
                  <svg
                    className={styles.component_1_check8}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.billing2}>Billing</div>
                </div>
                <div className={styles.frame_122}>
                  <svg
                    className={styles.component_1_check9}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4ZM2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M15.7071 9.29289C16.0976 9.68342 16.0976 10.3166 15.7071 10.7071L11.7071 14.7071C11.3166 15.0976 10.6834 15.0976 10.2929 14.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929C8.68342 10.9024 9.31658 10.9024 9.70711 11.2929L11 12.5858L14.2929 9.29289C14.6834 8.90237 15.3166 8.90237 15.7071 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.integrations2}>Integrations</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frame_1282}>
            <div className={styles.frame_125}>
              <div className={styles.frame_1632}>
                <div className={styles.frame_124}>
                  <div className={styles.pro2}>PRO</div>
                  <div className={styles.frame_122}>
                    <div className={styles._102}>$10</div>
                    <div className={styles._102}>/</div>
                    <div className={styles.month2}>month</div>
                  </div>
                </div>
              </div>
              <div className={styles.frame_126}>
                <div className={styles.enroll2}>Enroll</div>
              </div>
              <div className={styles.frame_102}>
                <div className={styles.frame_103}>
                  <svg
                    className={styles.component_1_check10}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.3334 4C7.9151 4 4.33337 7.58172 4.33337 12C4.33337 16.4183 7.9151 20 12.3334 20C16.7517 20 20.3334 16.4183 20.3334 12C20.3334 7.58172 16.7517 4 12.3334 4ZM2.33337 12C2.33337 6.47715 6.81053 2 12.3334 2C17.8562 2 22.3334 6.47715 22.3334 12C22.3334 17.5228 17.8562 22 12.3334 22C6.81053 22 2.33337 17.5228 2.33337 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.0405 9.29289C16.431 9.68342 16.431 10.3166 16.0405 10.7071L12.0405 14.7071C11.65 15.0976 11.0168 15.0976 10.6263 14.7071L8.62627 12.7071C8.23574 12.3166 8.23574 11.6834 8.62627 11.2929C9.01679 10.9024 9.64996 10.9024 10.0405 11.2929L11.3334 12.5858L14.6263 9.29289C15.0168 8.90237 15.65 8.90237 16.0405 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.up_to_3_website}>Up to 3 website</div>
                </div>
                <div className={styles.frame_142}>
                  <svg
                    className={styles.component_1_check11}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.3334 4C7.9151 4 4.33337 7.58172 4.33337 12C4.33337 16.4183 7.9151 20 12.3334 20C16.7517 20 20.3334 16.4183 20.3334 12C20.3334 7.58172 16.7517 4 12.3334 4ZM2.33337 12C2.33337 6.47715 6.81053 2 12.3334 2C17.8562 2 22.3334 6.47715 22.3334 12C22.3334 17.5228 17.8562 22 12.3334 22C6.81053 22 2.33337 17.5228 2.33337 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.0405 9.29289C16.431 9.68342 16.431 10.3166 16.0405 10.7071L12.0405 14.7071C11.65 15.0976 11.0168 15.0976 10.6263 14.7071L8.62627 12.7071C8.23574 12.3166 8.23574 11.6834 8.62627 11.2929C9.01679 10.9024 9.64996 10.9024 10.0405 11.2929L11.3334 12.5858L14.6263 9.29289C15.0168 8.90237 15.65 8.90237 16.0405 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.interest_list2}>Interest List</div>
                </div>
                <div className={styles.frame_112}>
                  <svg
                    className={styles.component_1_check12}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.3334 4C7.9151 4 4.33337 7.58172 4.33337 12C4.33337 16.4183 7.9151 20 12.3334 20C16.7517 20 20.3334 16.4183 20.3334 12C20.3334 7.58172 16.7517 4 12.3334 4ZM2.33337 12C2.33337 6.47715 6.81053 2 12.3334 2C17.8562 2 22.3334 6.47715 22.3334 12C22.3334 17.5228 17.8562 22 12.3334 22C6.81053 22 2.33337 17.5228 2.33337 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.0405 9.29289C16.431 9.68342 16.431 10.3166 16.0405 10.7071L12.0405 14.7071C11.65 15.0976 11.0168 15.0976 10.6263 14.7071L8.62627 12.7071C8.23574 12.3166 8.23574 11.6834 8.62627 11.2929C9.01679 10.9024 9.64996 10.9024 10.0405 11.2929L11.3334 12.5858L14.6263 9.29289C15.0168 8.90237 15.65 8.90237 16.0405 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.seo_management2}>SEO management</div>
                </div>
                <div className={styles.frame_174}>
                  <svg
                    className={styles.close_cr_fr4}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.21184 6.87883C7.60236 6.48831 8.23553 6.48831 8.62605 6.87883L12.3332 10.5859L16.0403 6.87883C16.4308 6.48831 17.064 6.48831 17.4545 6.87883C17.845 7.26935 17.845 7.90252 17.4545 8.29304L13.7474 12.0002L17.4545 15.7073C17.845 16.0978 17.845 16.7309 17.4545 17.1215C17.064 17.512 16.4308 17.512 16.0403 17.1215L12.3332 13.4144L8.62605 17.1215C8.23553 17.512 7.60236 17.512 7.21184 17.1215C6.82131 16.7309 6.82131 16.0978 7.21184 15.7073L10.9189 12.0002L7.21184 8.29304C6.82131 7.90252 6.82131 7.26935 7.21184 6.87883Z"
                      fill="#F04520"
                    />
                  </svg>

                  <div className={styles.settings2}>Settings</div>
                </div>
                <div className={styles.frame_192}>
                  <svg
                    className={styles.close_cr_fr5}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.21184 6.87883C7.60236 6.48831 8.23553 6.48831 8.62605 6.87883L12.3332 10.5859L16.0403 6.87883C16.4308 6.48831 17.064 6.48831 17.4545 6.87883C17.845 7.26935 17.845 7.90252 17.4545 8.29304L13.7474 12.0002L17.4545 15.7073C17.845 16.0978 17.845 16.7309 17.4545 17.1215C17.064 17.512 16.4308 17.512 16.0403 17.1215L12.3332 13.4144L8.62605 17.1215C8.23553 17.512 7.60236 17.512 7.21184 17.1215C6.82131 16.7309 6.82131 16.0978 7.21184 15.7073L10.9189 12.0002L7.21184 8.29304C6.82131 7.90252 6.82131 7.26935 7.21184 6.87883Z"
                      fill="#F04520"
                    />
                  </svg>

                  <div className={styles.billing2}>Billing</div>
                </div>
                <div className={styles.frame_122}>
                  <svg
                    className={styles.close_cr_fr6}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.21184 6.87883C7.60236 6.48831 8.23553 6.48831 8.62605 6.87883L12.3332 10.5859L16.0403 6.87883C16.4308 6.48831 17.064 6.48831 17.4545 6.87883C17.845 7.26935 17.845 7.90252 17.4545 8.29304L13.7474 12.0002L17.4545 15.7073C17.845 16.0978 17.845 16.7309 17.4545 17.1215C17.064 17.512 16.4308 17.512 16.0403 17.1215L12.3332 13.4144L8.62605 17.1215C8.23553 17.512 7.60236 17.512 7.21184 17.1215C6.82131 16.7309 6.82131 16.0978 7.21184 15.7073L10.9189 12.0002L7.21184 8.29304C6.82131 7.90252 6.82131 7.26935 7.21184 6.87883Z"
                      fill="#F04520"
                    />
                  </svg>

                  <div className={styles.integrations2}>Integrations</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.frame_129}>
            <div className={styles.frame_125}>
              <div className={styles.frame_124}>
                <div className={styles.ultimate}>Ultimate</div>
                <div className={styles.frame_122}>
                  <div className={styles._102}>$10</div>
                  <div className={styles._102}>/</div>
                  <div className={styles.month2}>month</div>
                </div>
              </div>
              <div className={styles.frame_126}>
                <div className={styles.enroll2}>Enroll</div>
              </div>
              <div className={styles.frame_102}>
                <div className={styles.frame_103}>
                  <svg
                    className={styles.component_1_check13}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.6666 4C8.24835 4 4.66663 7.58172 4.66663 12C4.66663 16.4183 8.24835 20 12.6666 20C17.0849 20 20.6666 16.4183 20.6666 12C20.6666 7.58172 17.0849 4 12.6666 4ZM2.66663 12C2.66663 6.47715 7.14378 2 12.6666 2C18.1895 2 22.6666 6.47715 22.6666 12C22.6666 17.5228 18.1895 22 12.6666 22C7.14378 22 2.66663 17.5228 2.66663 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.3737 9.29289C16.7643 9.68342 16.7643 10.3166 16.3737 10.7071L12.3737 14.7071C11.9832 15.0976 11.35 15.0976 10.9595 14.7071L8.95952 12.7071C8.56899 12.3166 8.56899 11.6834 8.95952 11.2929C9.35004 10.9024 9.98321 10.9024 10.3737 11.2929L11.6666 12.5858L14.9595 9.29289C15.35 8.90237 15.9832 8.90237 16.3737 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.unlimited_websites}>Unlimited websites</div>
                </div>
                <div className={styles.frame_142}>
                  <svg
                    className={styles.component_1_check14}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.6666 4C8.24835 4 4.66663 7.58172 4.66663 12C4.66663 16.4183 8.24835 20 12.6666 20C17.0849 20 20.6666 16.4183 20.6666 12C20.6666 7.58172 17.0849 4 12.6666 4ZM2.66663 12C2.66663 6.47715 7.14378 2 12.6666 2C18.1895 2 22.6666 6.47715 22.6666 12C22.6666 17.5228 18.1895 22 12.6666 22C7.14378 22 2.66663 17.5228 2.66663 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.3737 9.29289C16.7643 9.68342 16.7643 10.3166 16.3737 10.7071L12.3737 14.7071C11.9832 15.0976 11.35 15.0976 10.9595 14.7071L8.95952 12.7071C8.56899 12.3166 8.56899 11.6834 8.95952 11.2929C9.35004 10.9024 9.98321 10.9024 10.3737 11.2929L11.6666 12.5858L14.9595 9.29289C15.35 8.90237 15.9832 8.90237 16.3737 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.interest_list2}>Interest List</div>
                </div>
                <div className={styles.frame_112}>
                  <svg
                    className={styles.component_1_check15}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.6666 4C8.24835 4 4.66663 7.58172 4.66663 12C4.66663 16.4183 8.24835 20 12.6666 20C17.0849 20 20.6666 16.4183 20.6666 12C20.6666 7.58172 17.0849 4 12.6666 4ZM2.66663 12C2.66663 6.47715 7.14378 2 12.6666 2C18.1895 2 22.6666 6.47715 22.6666 12C22.6666 17.5228 18.1895 22 12.6666 22C7.14378 22 2.66663 17.5228 2.66663 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.3737 9.29289C16.7643 9.68342 16.7643 10.3166 16.3737 10.7071L12.3737 14.7071C11.9832 15.0976 11.35 15.0976 10.9595 14.7071L8.95952 12.7071C8.56899 12.3166 8.56899 11.6834 8.95952 11.2929C9.35004 10.9024 9.98321 10.9024 10.3737 11.2929L11.6666 12.5858L14.9595 9.29289C15.35 8.90237 15.9832 8.90237 16.3737 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.seo_management2}>SEO management</div>
                </div>
                <div className={styles.frame_174}>
                  <svg
                    className={styles.component_1_check16}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.6666 4C8.24835 4 4.66663 7.58172 4.66663 12C4.66663 16.4183 8.24835 20 12.6666 20C17.0849 20 20.6666 16.4183 20.6666 12C20.6666 7.58172 17.0849 4 12.6666 4ZM2.66663 12C2.66663 6.47715 7.14378 2 12.6666 2C18.1895 2 22.6666 6.47715 22.6666 12C22.6666 17.5228 18.1895 22 12.6666 22C7.14378 22 2.66663 17.5228 2.66663 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.3737 9.29289C16.7643 9.68342 16.7643 10.3166 16.3737 10.7071L12.3737 14.7071C11.9832 15.0976 11.35 15.0976 10.9595 14.7071L8.95952 12.7071C8.56899 12.3166 8.56899 11.6834 8.95952 11.2929C9.35004 10.9024 9.98321 10.9024 10.3737 11.2929L11.6666 12.5858L14.9595 9.29289C15.35 8.90237 15.9832 8.90237 16.3737 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.settings2}>Settings</div>
                </div>
                <div className={styles.frame_192}>
                  <svg
                    className={styles.component_1_check17}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.6666 4C8.24835 4 4.66663 7.58172 4.66663 12C4.66663 16.4183 8.24835 20 12.6666 20C17.0849 20 20.6666 16.4183 20.6666 12C20.6666 7.58172 17.0849 4 12.6666 4ZM2.66663 12C2.66663 6.47715 7.14378 2 12.6666 2C18.1895 2 22.6666 6.47715 22.6666 12C22.6666 17.5228 18.1895 22 12.6666 22C7.14378 22 2.66663 17.5228 2.66663 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.3737 9.29289C16.7643 9.68342 16.7643 10.3166 16.3737 10.7071L12.3737 14.7071C11.9832 15.0976 11.35 15.0976 10.9595 14.7071L8.95952 12.7071C8.56899 12.3166 8.56899 11.6834 8.95952 11.2929C9.35004 10.9024 9.98321 10.9024 10.3737 11.2929L11.6666 12.5858L14.9595 9.29289C15.35 8.90237 15.9832 8.90237 16.3737 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.billing2}>Billing</div>
                </div>
                <div className={styles.frame_122}>
                  <svg
                    className={styles.component_1_check18}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M12.6666 4C8.24835 4 4.66663 7.58172 4.66663 12C4.66663 16.4183 8.24835 20 12.6666 20C17.0849 20 20.6666 16.4183 20.6666 12C20.6666 7.58172 17.0849 4 12.6666 4ZM2.66663 12C2.66663 6.47715 7.14378 2 12.6666 2C18.1895 2 22.6666 6.47715 22.6666 12C22.6666 17.5228 18.1895 22 12.6666 22C7.14378 22 2.66663 17.5228 2.66663 12Z"
                      fill="#4541E5"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M16.3737 9.29289C16.7643 9.68342 16.7643 10.3166 16.3737 10.7071L12.3737 14.7071C11.9832 15.0976 11.35 15.0976 10.9595 14.7071L8.95952 12.7071C8.56899 12.3166 8.56899 11.6834 8.95952 11.2929C9.35004 10.9024 9.98321 10.9024 10.3737 11.2929L11.6666 12.5858L14.9595 9.29289C15.35 8.90237 15.9832 8.90237 16.3737 9.29289Z"
                      fill="#4541E5"
                    />
                  </svg>

                  <div className={styles.integrations2}>Integrations</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.frame_273}>
          <div className={styles.frame_270}>
            <div className={styles.content8}>
              <div className={styles.content9}>
                <div className={styles.stars}>
                  <div className={styles.star_icon}>
                    <svg
                      className={styles.star_background}
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5383 0.711526C10.7091 0.30088 11.2909 0.300881 11.4617 0.711527L14.0568 6.95089C14.1288 7.12401 14.2916 7.24229 14.4785 7.25728L21.2144 7.79729C21.6577 7.83283 21.8375 8.38609 21.4997 8.67542L16.3676 13.0716C16.2252 13.1936 16.1631 13.3849 16.2066 13.5673L17.7745 20.1404C17.8777 20.573 17.4071 20.915 17.0275 20.6831L11.2606 17.1608C11.1006 17.063 10.8994 17.063 10.7394 17.1608L4.97249 20.6831C4.59294 20.915 4.12231 20.573 4.22551 20.1404L5.79343 13.5673C5.83694 13.3849 5.77475 13.1936 5.63236 13.0716L0.5003 8.67542C0.162531 8.38609 0.342295 7.83283 0.785622 7.79729L7.52154 7.25728C7.70843 7.24229 7.87124 7.12401 7.94324 6.95089L10.5383 0.711526Z"
                        fill="#F2F4F7"
                      />
                    </svg>

                    <svg
                      className={styles.star}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_593_50904)">
                        <path
                          d="M11.5383 1.71153C11.7091 1.30088 12.2909 1.30088 12.4617 1.71153L15.0568 7.95089C15.1288 8.12401 15.2916 8.24229 15.4785 8.25728L22.2144 8.79729C22.6577 8.83283 22.8375 9.38609 22.4997 9.67542L17.3676 14.0716C17.2252 14.1936 17.1631 14.3849 17.2066 14.5673L18.7745 21.1404C18.8777 21.573 18.4071 21.915 18.0275 21.6831L12.2606 18.1608C12.1006 18.063 11.8994 18.063 11.7394 18.1608L5.97249 21.6831C5.59294 21.915 5.12231 21.573 5.22551 21.1404L6.79343 14.5673C6.83694 14.3849 6.77475 14.1936 6.63236 14.0716L1.5003 9.67542C1.16253 9.38609 1.34229 8.83283 1.78562 8.79729L8.52154 8.25728C8.70843 8.24229 8.87124 8.12401 8.94324 7.95089L11.5383 1.71153Z"
                          fill="#FEC84B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_593_50904">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className={styles.star_icon}>
                    <svg
                      className={styles.star_background2}
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5383 0.711526C10.7091 0.30088 11.2909 0.300881 11.4617 0.711527L14.0568 6.95089C14.1288 7.12401 14.2916 7.24229 14.4785 7.25728L21.2144 7.79729C21.6577 7.83283 21.8375 8.38609 21.4997 8.67542L16.3676 13.0716C16.2252 13.1936 16.1631 13.3849 16.2066 13.5673L17.7745 20.1404C17.8777 20.573 17.4071 20.915 17.0275 20.6831L11.2606 17.1608C11.1006 17.063 10.8994 17.063 10.7394 17.1608L4.97249 20.6831C4.59294 20.915 4.12231 20.573 4.22551 20.1404L5.79343 13.5673C5.83694 13.3849 5.77475 13.1936 5.63236 13.0716L0.5003 8.67542C0.162531 8.38609 0.342295 7.83283 0.785622 7.79729L7.52154 7.25728C7.70843 7.24229 7.87124 7.12401 7.94324 6.95089L10.5383 0.711526Z"
                        fill="#F2F4F7"
                      />
                    </svg>

                    <svg
                      className={styles.star3}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_593_50908)">
                        <path
                          d="M11.5383 1.71153C11.7091 1.30088 12.2909 1.30088 12.4617 1.71153L15.0568 7.95089C15.1288 8.12401 15.2916 8.24229 15.4785 8.25728L22.2144 8.79729C22.6577 8.83283 22.8375 9.38609 22.4997 9.67542L17.3676 14.0716C17.2252 14.1936 17.1631 14.3849 17.2066 14.5673L18.7745 21.1404C18.8777 21.573 18.4071 21.915 18.0275 21.6831L12.2606 18.1608C12.1006 18.063 11.8994 18.063 11.7394 18.1608L5.97249 21.6831C5.59294 21.915 5.12231 21.573 5.22551 21.1404L6.79343 14.5673C6.83694 14.3849 6.77475 14.1936 6.63236 14.0716L1.5003 9.67542C1.16253 9.38609 1.34229 8.83283 1.78562 8.79729L8.52154 8.25728C8.70843 8.24229 8.87124 8.12401 8.94324 7.95089L11.5383 1.71153Z"
                          fill="#FEC84B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_593_50908">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className={styles.star_icon}>
                    <svg
                      className={styles.star_background3}
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5383 0.711526C10.7091 0.30088 11.2909 0.300881 11.4617 0.711527L14.0568 6.95089C14.1288 7.12401 14.2916 7.24229 14.4785 7.25728L21.2144 7.79729C21.6577 7.83283 21.8375 8.38609 21.4997 8.67542L16.3676 13.0716C16.2252 13.1936 16.1631 13.3849 16.2066 13.5673L17.7745 20.1404C17.8777 20.573 17.4071 20.915 17.0275 20.6831L11.2606 17.1608C11.1006 17.063 10.8994 17.063 10.7394 17.1608L4.97249 20.6831C4.59294 20.915 4.12231 20.573 4.22551 20.1404L5.79343 13.5673C5.83694 13.3849 5.77475 13.1936 5.63236 13.0716L0.5003 8.67542C0.162531 8.38609 0.342295 7.83283 0.785622 7.79729L7.52154 7.25728C7.70843 7.24229 7.87124 7.12401 7.94324 6.95089L10.5383 0.711526Z"
                        fill="#F2F4F7"
                      />
                    </svg>

                    <svg
                      className={styles.star5}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_593_50912)">
                        <path
                          d="M11.5383 1.71153C11.7091 1.30088 12.2909 1.30088 12.4617 1.71153L15.0568 7.95089C15.1288 8.12401 15.2916 8.24229 15.4785 8.25728L22.2144 8.79729C22.6577 8.83283 22.8375 9.38609 22.4997 9.67542L17.3676 14.0716C17.2252 14.1936 17.1631 14.3849 17.2066 14.5673L18.7745 21.1404C18.8777 21.573 18.4071 21.915 18.0275 21.6831L12.2606 18.1608C12.1006 18.063 11.8994 18.063 11.7394 18.1608L5.97249 21.6831C5.59294 21.915 5.12231 21.573 5.22551 21.1404L6.79343 14.5673C6.83694 14.3849 6.77475 14.1936 6.63236 14.0716L1.5003 9.67542C1.16253 9.38609 1.34229 8.83283 1.78562 8.79729L8.52154 8.25728C8.70843 8.24229 8.87124 8.12401 8.94324 7.95089L11.5383 1.71153Z"
                          fill="#FEC84B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_593_50912">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className={styles.star_icon}>
                    <svg
                      className={styles.star_background4}
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5383 0.711526C10.7091 0.30088 11.2909 0.300881 11.4617 0.711527L14.0568 6.95089C14.1288 7.12401 14.2916 7.24229 14.4785 7.25728L21.2144 7.79729C21.6577 7.83283 21.8375 8.38609 21.4997 8.67542L16.3676 13.0716C16.2252 13.1936 16.1631 13.3849 16.2066 13.5673L17.7745 20.1404C17.8777 20.573 17.4071 20.915 17.0275 20.6831L11.2606 17.1608C11.1006 17.063 10.8994 17.063 10.7394 17.1608L4.97249 20.6831C4.59294 20.915 4.12231 20.573 4.22551 20.1404L5.79343 13.5673C5.83694 13.3849 5.77475 13.1936 5.63236 13.0716L0.5003 8.67542C0.162531 8.38609 0.342295 7.83283 0.785622 7.79729L7.52154 7.25728C7.70843 7.24229 7.87124 7.12401 7.94324 6.95089L10.5383 0.711526Z"
                        fill="#F2F4F7"
                      />
                    </svg>

                    <svg
                      className={styles.star7}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_593_50916)">
                        <path
                          d="M11.5383 1.71153C11.7091 1.30088 12.2909 1.30088 12.4617 1.71153L15.0568 7.95089C15.1288 8.12401 15.2916 8.24229 15.4785 8.25728L22.2144 8.79729C22.6577 8.83283 22.8375 9.38609 22.4997 9.67542L17.3676 14.0716C17.2252 14.1936 17.1631 14.3849 17.2066 14.5673L18.7745 21.1404C18.8777 21.573 18.4071 21.915 18.0275 21.6831L12.2606 18.1608C12.1006 18.063 11.8994 18.063 11.7394 18.1608L5.97249 21.6831C5.59294 21.915 5.12231 21.573 5.22551 21.1404L6.79343 14.5673C6.83694 14.3849 6.77475 14.1936 6.63236 14.0716L1.5003 9.67542C1.16253 9.38609 1.34229 8.83283 1.78562 8.79729L8.52154 8.25728C8.70843 8.24229 8.87124 8.12401 8.94324 7.95089L11.5383 1.71153Z"
                          fill="#FEC84B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_593_50916">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <div className={styles.star_icon}>
                    <svg
                      className={styles.star_background5}
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.5383 0.711526C10.7091 0.30088 11.2909 0.300881 11.4617 0.711527L14.0568 6.95089C14.1288 7.12401 14.2916 7.24229 14.4785 7.25728L21.2144 7.79729C21.6577 7.83283 21.8375 8.38609 21.4997 8.67542L16.3676 13.0716C16.2252 13.1936 16.1631 13.3849 16.2066 13.5673L17.7745 20.1404C17.8777 20.573 17.4071 20.915 17.0275 20.6831L11.2606 17.1608C11.1006 17.063 10.8994 17.063 10.7394 17.1608L4.97249 20.6831C4.59294 20.915 4.12231 20.573 4.22551 20.1404L5.79343 13.5673C5.83694 13.3849 5.77475 13.1936 5.63236 13.0716L0.5003 8.67542C0.162531 8.38609 0.342295 7.83283 0.785622 7.79729L7.52154 7.25728C7.70843 7.24229 7.87124 7.12401 7.94324 6.95089L10.5383 0.711526Z"
                        fill="#F2F4F7"
                      />
                    </svg>

                    <svg
                      className={styles.star9}
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_593_50920)">
                        <path
                          d="M11.5383 1.71153C11.7091 1.30088 12.2909 1.30088 12.4617 1.71153L15.0568 7.95089C15.1288 8.12401 15.2916 8.24229 15.4785 8.25728L22.2144 8.79729C22.6577 8.83283 22.8375 9.38609 22.4997 9.67542L17.3676 14.0716C17.2252 14.1936 17.1631 14.3849 17.2066 14.5673L18.7745 21.1404C18.8777 21.573 18.4071 21.915 18.0275 21.6831L12.2606 18.1608C12.1006 18.063 11.8994 18.063 11.7394 18.1608L5.97249 21.6831C5.59294 21.915 5.12231 21.573 5.22551 21.1404L6.79343 14.5673C6.83694 14.3849 6.77475 14.1936 6.63236 14.0716L1.5003 9.67542C1.16253 9.38609 1.34229 8.83283 1.78562 8.79729L8.52154 8.25728C8.70843 8.24229 8.87124 8.12401 8.94324 7.95089L11.5383 1.71153Z"
                          fill="#FEC84B"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_593_50920">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className={styles.heading3}>
                  This website is a revolutionary web design tool that seamlessly
                  integrates artificial intelligence, making website creation both
                  efficient and enjoyable. Love the simplicity of the service!
                </div>
              </div>
              <div className={styles.txt4}>
                <div className={styles.text4}>— Renee Wells</div>
                <div className={styles.supporting_text3}>CEO, Olink</div>
              </div>
            </div>
            <div className={styles.frame_272}>
              <img className={styles.rectangle_532} src="/images/home/rectangle-531.png" />
            </div>
          </div>
          <div className={styles.arrow}>
            <svg
              className={styles.chevron_down}
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M27.5 11L16.5 22L27.5 33"
                stroke="#272727"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <svg
              className={styles.chevron_down2}
              width="44"
              height="44"
              viewBox="0 0 44 44"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.5 33L27.5 22L16.5 11"
                stroke="#272727"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className={styles.content10}>
          <div className={styles.text5}>
            <div className={styles.transform_your_vision_into_a_site}>
              Transform your vision into a site
            </div>
            <div className={styles.convenient_generation_of_your_ideas}>
              <span
                ><span className={styles.convenient_generation_of_your_ideas_span}
                  >Convenient generation of</span
                ><span className={styles.convenient_generation_of_your_ideas_span2}> </span
                ><span className={styles.convenient_generation_of_your_ideas_span3}
                  >your ideas</span
                ></span
              >
            </div>
          </div>
          <div className={styles.action2}>
            <div className={styles.button2}>
              <div className={styles.get_pro}>Get Pro</div>
            </div>
            <div className={styles.button3} /*onclick="navigateToQnA()"*/>
              <img className={styles.ellipse_10} src="/images/home/ellipse-100.png" />
              <div className={styles.get_started_free} onClick={qnapage}>Get started FREE</div>
              <svg
                className={styles.chevron_right}
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.79289 8.29289C9.40237 8.68342 9.40237 9.31658 9.79289 9.70711L12.0858 12L9.79289 14.2929C9.40237 14.6834 9.40237 15.3166 9.79289 15.7071C10.1834 16.0976 10.8166 16.0976 11.2071 15.7071L14.2071 12.7071C14.5976 12.3166 14.5976 11.6834 14.2071 11.2929L11.2071 8.29289C10.8166 7.90237 10.1834 7.90237 9.79289 8.29289Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.header2}>
          <div className={styles.frame_274}>
            <div className={styles.ellipse_22}></div>
            <div className={styles.ai_builder2}>Appefy</div>
          </div>
          <div className={styles.button3}>
            <img className={styles.ellipse_10} src="/images/home/ellipse-101.png" />
            <div className={styles.get_started_free} onClick={qnapage} >Get started FREE</div>
            <svg
              className={styles.chevron_right2}
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.29289 8.29289C8.90237 8.68342 8.90237 9.31658 9.29289 9.70711L11.5858 12L9.29289 14.2929C8.90237 14.6834 8.90237 15.3166 9.29289 15.7071C9.68342 16.0976 10.3166 16.0976 10.7071 15.7071L13.7071 12.7071C14.0976 12.3166 14.0976 11.6834 13.7071 11.2929L10.7071 8.29289C10.3166 7.90237 9.68342 7.90237 9.29289 8.29289Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
        <div className={styles.container2}>
          <div className={styles.content11}>
            <div className={styles.macbook_pro_16_mockup}>
              <img className={styles.shadow} src="/images/home/shadow0.png" /><img
                className={styles.mac_book_pro_16}
                src="/images/home/mac-book-pro-160.png"
              /><img className={styles.camera} src="/images/home/camera0.png" /><img
                className={styles.logo}
                src="/images/home/logo0.png"
              />
              <div
                className={styles.screen_mockup_replace_fill}
                style={{
                  background: `url(screen-mockup-replace-fill0.png) center`,
                  backgroundSize: 'cover'
                }}
                
              >
                <div className={styles.chat2}>
                  <div className={styles.frame_162}>
                    <div className={styles.frame_275}>
                      <div className={styles.frame_282}>
                        <div className={styles.ai_chat}>AI Chat</div>
                      </div>
                      <svg
                        className={styles.alert_circle}
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_593_50959)">
                          <path
                            d="M6.47544 3.83831V5.71228M6.47544 7.58626H6.48012M11.1604 5.71228C11.1604 8.29971 9.06286 10.3972 6.47544 10.3972C3.88802 10.3972 1.7905 8.29971 1.7905 5.71228C1.7905 3.12486 3.88802 1.02734 6.47544 1.02734C9.06286 1.02734 11.1604 3.12486 11.1604 5.71228Z"
                            stroke="#5752FF"
                            stroke-width="1.05411"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_593_50959">
                            <rect
                              width="11.2439"
                              height="11.2439"
                              fill="white"
                              transform="translate(0.853394 0.0898438)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <div className={styles.frame_193}>
                      <div className={styles.frame_175}>
                        <svg
                          className={styles.frame_87}
                          width="11.24571418762207"
                          height="29.86857032775879"
                        ></svg>
                        <div className={styles.frame_67}>
                          <div className={styles.frame_54}>
                            <div className={styles.hi_how_can_i_help_you_today}>
                              Hi! How can I help you today?
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_72}>
                          <div className={styles.rectangle_810}></div>
                          <div className={styles.rectangle_106}></div>
                          <div className={styles.rectangle_1110}></div>
                        </div>
                      </div>
                      <div className={styles.frame_176}>
                        <div className={styles.ellipse_23}></div>
                        <svg
                          className={styles.frame_88}
                          width="21"
                          height="31"
                          viewBox="0 0 21 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.34894 11.7809C9.34894 5.57004 14.3838 0.535156 20.5947 0.535156V14.5923H9.34894V11.7809Z"
                            fill="#F3F2FE"
                          />
                          <rect
                            x="9.34869"
                            y="14.5938"
                            width="11.2457"
                            height="1.75428"
                            fill="#F3F2FE"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.5942 16.3477H9.34848V19.1591C9.34848 19.5231 9.36578 19.8832 9.3996 20.2384C9.36629 20.378 9.34869 20.5349 9.34869 20.7102C9.34869 24.8707 5.54857 28.419 0.211548 29.7944C1.74283 30.1891 3.40066 30.4048 5.13155 30.4048C8.81344 30.4048 11.7695 29.5921 13.8601 28.1665C15.7373 29.5722 18.0685 30.4048 20.5942 30.4048V16.3477Z"
                            fill="#F3F2FE"
                          />
                        </svg>

                        <div className={styles.frame_69}>
                          <div className={styles.frame_54}>
                            <div
                              className={styles.i_will_help_you_to_build_the_best_website}
                            >
                              I will help you to build the best website!
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_72}>
                          <div className={styles.rectangle_812}></div>
                          <div className={styles.rectangle_107}></div>
                          <div className={styles.rectangle_1112}></div>
                        </div>
                      </div>
                      <div className={styles.frame_177}>
                        <div className={styles.frame_73}>
                          <div className={styles.rectangle_813}></div>
                          <div className={styles.rectangle_108}></div>
                          <div className={styles.rectangle_1113}></div>
                        </div>
                        <div className={styles.frame_610}>
                          <div className={styles.frame_54}>
                            <div className={styles.website_for_karate_school}>
                              Website for karate school
                            </div>
                          </div>
                        </div>
                        <svg
                          className={styles.frame_89}
                          width="21"
                          height="30"
                          viewBox="0 0 21 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.8459 11.2731C11.8459 5.06223 6.81101 0.0273438 0.600172 0.0273438V14.0845H11.8459V11.2731Z"
                            fill="#6C4ADE"
                          />
                          <rect
                            width="11.2457"
                            height="1.75428"
                            transform="matrix(-1 0 0 1 11.8459 14.0859)"
                            fill="#6C4ADE"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.600389 15.8398H11.8461V18.6513C11.8461 19.0153 11.8288 19.3754 11.795 19.7306C11.8283 19.8702 11.8459 20.0271 11.8459 20.2024C11.8459 24.3629 15.646 27.9112 20.983 29.2866C19.4518 29.6813 17.7939 29.897 16.063 29.897C12.3811 29.897 9.42508 29.0843 7.33451 27.6586C5.45728 29.0644 3.12607 29.897 0.600389 29.897V15.8398Z"
                            fill="#6C4ADE"
                          />
                        </svg>

                        <img className={styles.ellipse_24} src="/images/home/ellipse-24.png" />
                      </div>
                      <div className={styles.frame_182}>
                        <div className={styles.ellipse_23}></div>
                        <svg
                          className={styles.frame_810}
                          width="21"
                          height="70"
                          viewBox="0 0 21 70"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.34857 11.7653C9.34857 5.55442 14.3834 0.519531 20.5943 0.519531V14.5767H9.34857V11.7653Z"
                            fill="#F3F2FE"
                          />
                          <rect
                            x="9.34857"
                            y="14.5781"
                            width="11.2457"
                            height="40.7541"
                            fill="#F3F2FE"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.5942 55.332H9.34848V58.1435C9.34848 58.5075 9.36578 58.8675 9.3996 59.2227C9.36629 59.3624 9.34869 59.5193 9.34869 59.6946C9.34869 63.8551 5.54857 67.4034 0.211548 68.7788C1.74283 69.1734 3.40066 69.3892 5.13155 69.3892C8.81344 69.3892 11.7695 68.5765 13.8601 67.1508C15.7373 68.5566 18.0685 69.3892 20.5942 69.3892V55.332Z"
                            fill="#F3F2FE"
                          />
                        </svg>

                        <div className={styles.frame_611}>
                          <div className={styles.frame_55}>
                            <div
                              className={styles.that_sounds_great_i_found_a_couple_colour_palettes_that_will_look_great_with_karate_school_please_choose_one_from_the_following_options}
                            >
                              That sounds great! I found a couple colour palettes
                              that will look great with karate school.<br /><br />Please
                              choose one from the following options:
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_72}>
                          <div className={styles.rectangle_812}></div>
                          <div className={styles.rectangle_107}></div>
                          <div className={styles.rectangle_1112}></div>
                        </div>
                      </div>
                      <div className={styles.frame_194}>
                        <div className={styles.frame_143}>
                          <img
                            className={styles.screenshot_2023_11_20_at_1_00_2}
                            src="/images/home/screenshot-2023-11-20-at-1-00-20.png"
                          />
                        </div>
                        <svg
                          className={styles.frame_811}
                          width="21"
                          height="68"
                          viewBox="0 0 21 68"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.8461 11.2574C11.8461 5.0466 6.81119 0.0117188 0.600355 0.0117188V14.0689H11.8461V11.2574Z"
                            fill="#6C4ADE"
                          />
                          <rect
                            width="11.2457"
                            height="39.36"
                            transform="matrix(-1 0 0 1 11.8461 14.0703)"
                            fill="#6C4ADE"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.600573 53.4297H11.8463V56.2411C11.8463 56.6052 11.829 56.9652 11.7952 57.3204C11.8285 57.4601 11.8461 57.6169 11.8461 57.7923C11.8461 61.9527 15.6462 65.5011 20.9832 66.8765C19.4519 67.2711 17.7941 67.4868 16.0632 67.4868C12.3813 67.4868 9.42527 66.6741 7.3347 65.2485C5.45746 66.6542 3.12625 67.4868 0.600573 67.4868V53.4297Z"
                            fill="#6C4ADE"
                          />
                        </svg>

                        <img className={styles.ellipse_24} src="/images/home/ellipse-26.png" />
                      </div>
                      <div className={styles.frame_20}>
                        <div className={styles.ellipse_23}></div>
                        <svg
                          className={styles.frame_812}
                          width="21"
                          height="30"
                          viewBox="0 0 21 30"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.34869 11.3551C9.34869 5.14426 14.3836 0.109375 20.5944 0.109375V14.1665H9.34869V11.3551Z"
                            fill="#F3F2FE"
                          />
                          <rect
                            x="9.34869"
                            y="14.168"
                            width="11.2457"
                            height="1.75428"
                            fill="#F3F2FE"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.5942 15.9219H9.34848V18.7333C9.34848 19.0974 9.36578 19.4574 9.3996 19.8126C9.36629 19.9523 9.34869 20.1091 9.34869 20.2845C9.34869 24.4449 5.54857 27.9933 0.211548 29.3687C1.74283 29.7633 3.40066 29.979 5.13155 29.979C8.81344 29.979 11.7695 29.1663 13.8601 27.7407C15.7373 29.1464 18.0685 29.979 20.5942 29.979V15.9219Z"
                            fill="#F3F2FE"
                          />
                        </svg>

                        <div className={styles.frame_611}>
                          <div className={styles.frame_55}>
                            <div
                              className={styles.what_is_your_goal_for_creating_the_website}
                            >
                              What is your goal for creating the website?
                            </div>
                          </div>
                        </div>
                        <div className={styles.frame_72}>
                          <div className={styles.rectangle_812}></div>
                          <div className={styles.rectangle_107}></div>
                          <div className={styles.rectangle_1112}></div>
                        </div>
                      </div>
                      <div className={styles.frame_21}>
                        <div className={styles.contnet}>
                          <div className={styles.answer}>
                            <div
                              className={styles.provide_information_about_our_company_organization}
                            >
                              Provide information about our company/organization
                            </div>
                          </div>
                          <div className={styles.answer2}>
                            <div className={styles.sell_products_or_services_online}>
                              Sell products or services online
                            </div>
                          </div>
                          <div className={styles.answer2}>
                            <div className={styles.or_write_your_answer_below}>
                              Or write your answer below
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={styles.frame_222}>
                        <div className={styles.frame_143}>
                          <div
                            className={styles.provide_information_about_our_company_organization2}
                          >
                            Provide information about our company/organization
                          </div>
                        </div>
                        <svg
                          className={styles.frame_813}
                          width="21"
                          height="35"
                          viewBox="0 0 21 35"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M11.8459 12.2067C11.8459 5.99582 6.81101 0.960938 0.600172 0.960938V15.0181H11.8459V12.2067Z"
                            fill="#6C4ADE"
                          />
                          <rect
                            width="11.2457"
                            height="5.37714"
                            transform="matrix(-1 0 0 1 11.8459 15.0195)"
                            fill="#6C4ADE"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M0.600389 20.3945H11.8461V23.206C11.8461 23.57 11.8288 23.93 11.795 24.2852C11.8283 24.4249 11.8459 24.5818 11.8459 24.7571C11.8459 28.9176 15.646 32.4659 20.983 33.8413C19.4518 34.2359 17.7939 34.4517 16.063 34.4517C12.3811 34.4517 9.42508 33.639 7.33451 32.2133C5.45728 33.6191 3.12607 34.4517 0.600389 34.4517V20.3945Z"
                            fill="#6C4ADE"
                          />
                        </svg>

                        <img className={styles.ellipse_24} src="/images/home/ellipse-28.png" />
                      </div>
                    </div>
                    <div className={styles.frame_183}>
                      <div className={styles.frame_32}>
                        <div className={styles.frame_42}>
                          <div className={styles.line_12}></div>
                          <div className={styles.describe_your_idea}>
                            Describe your idea...
                          </div>
                        </div>
                        <svg
                          className={styles.send5}
                          width="18"
                          height="17"
                          viewBox="0 0 18 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_593_51068)">
                            <path
                              d="M16.2576 1.53516L8.52621 9.26658M16.2576 1.53516L11.3376 15.5923L8.52621 9.26658M16.2576 1.53516L2.2005 6.45516L8.52621 9.26658"
                              stroke="#5D5D5D"
                              stroke-width="1.40571"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_593_51068">
                              <rect
                                width="16.8686"
                                height="16.8686"
                                fill="white"
                                transform="translate(0.794434 0.128906)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className={styles.frame_25}>
                    <div className={styles.frame_276}>
                      <div className={styles.ellipse_25}></div>
                      <div className={styles.ai_builder3}>AI BUILDER</div>
                    </div>
                    <div className={styles.action3}>
                      <div className={styles.frame_232}>
                        <div className={styles.log_in}>Log in</div>
                      </div>
                      <div className={styles.frame_242}>
                        <div className={styles.sign_up}>Sign up</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.frame_178}>
                <div className={styles.message3}>
                  <div className={styles.frame_74}>
                    <div className={styles.rectangle_8}></div>
                    <div className={styles.rectangle_10}></div>
                    <div className={styles.rectangle_11}></div>
                  </div>
                  <div className={styles.frame_612}>
                    <div className={styles.frame_5}>
                      <div className={styles.website_for_karate_school2}>
                        Website for karate school
                      </div>
                    </div>
                  </div>
                  <svg
                    className={styles.frame_814}
                    width="47"
                    height="63"
                    viewBox="0 0 47 63"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.0984 25.0496C27.5682 13.079 19.0557 2.18349 7.08509 0.713692L3.75846 27.807L25.4331 30.4683L26.0984 25.0496Z"
                      fill="#6C4ADE"
                    />
                    <rect
                      width="21.8374"
                      height="3.16261"
                      transform="matrix(-0.992546 -0.121869 -0.121869 0.992546 25.433 30.4688)"
                      fill="#6C4ADE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M3.37293 30.946L25.0475 33.6073L24.3822 39.026C24.296 39.728 24.1774 40.4182 24.028 41.0951C24.059 41.3718 24.0558 41.6778 24.0143 42.0153C23.0298 50.034 29.5143 57.7723 39.4752 61.6862C36.4305 62.0844 33.1842 62.1079 29.8481 61.6983C22.752 60.827 17.247 58.5612 13.5551 55.3188C9.60431 57.5839 4.91419 58.637 0.0462939 58.0393L3.37293 30.946Z"
                      fill="#6C4ADE"
                    />
                  </svg>
                </div>
              </div>
              <div className={styles.frame_184}>
                <div className={styles.ellipse_26}></div>
                <div className={styles.message4}>
                  <svg
                    className={styles.frame_815}
                    width="40"
                    height="62"
                    viewBox="0 0 40 62"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.7709 24.4982C12.3253 12.7243 20.6979 2.00776 32.4718 0.562106L35.7438 27.2102L14.4253 29.8278L13.7709 24.4982Z"
                      fill="#F3F2FE"
                    />
                    <rect
                      x="14.4253"
                      y="29.8281"
                      width="21.4786"
                      height="3.70041"
                      transform="rotate(-7 14.4253 29.8281)"
                      fill="#F3F2FE"
                    />
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M36.1947 30.8847L14.8762 33.5023L15.5306 38.8319C15.6155 39.523 15.7323 40.2024 15.8793 40.8688C15.849 41.1407 15.8522 41.4413 15.8929 41.7727C16.8613 49.6597 10.4834 57.2708 0.686117 61.1204C3.68082 61.5121 6.87379 61.5352 10.155 61.1323C17.1347 60.2753 22.5493 58.0467 26.1806 54.8575C30.0663 57.0851 34.6791 58.1207 39.4667 57.5328L36.1947 30.8847Z"
                      fill="#F3F2FE"
                    />
                  </svg>

                  <div className={styles.frame_613}>
                    <div className={styles.frame_56}>
                      <div className={styles.choose_the_colot_of_your_website}>
                        Choose the colot of your website
                      </div>
                    </div>
                  </div>
                  <div className={styles.frame_75}>
                    <div className={styles.rectangle_816}></div>
                    <div className={styles.rectangle_1010}></div>
                    <div className={styles.rectangle_1119}></div>
                  </div>
                </div>
              </div>
              <svg
                className={styles.play_button}
                width="80"
                height="80"
                viewBox="0 0 80 80"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_b_593_51110)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80ZM33.75 54.6721L56.25 42.096C57.9167 41.1645 57.9167 38.8355 56.25 37.904L33.75 25.3279C32.0833 24.3963 30 25.5608 30 27.4239L30 52.5761C30 54.4392 32.0833 55.6037 33.75 54.6721Z"
                    fill="#6C4ADE"
                    fill-opacity="0.18"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_b_593_51110"
                    x="-16"
                    y="-16"
                    width="112"
                    height="112"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="8" />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_593_51110"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_593_51110"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className={styles.img2}>
          <div className={styles.content12}>
            <div className={styles.rectangle_512}></div>
          </div>
          <div className={styles.dashboard}>
            <div className={styles.header3}>
              <div className={styles.frame_982}>
                <div className={styles.frame_274}>
                  <div className={styles.ellipse_27}></div>
                  <div className={styles.ai_builder4}>AI BUILDER</div>
                </div>
                <div className={styles.frame_983}>
                  <div className={styles.dashboard2}>
                    <div className={styles.dashboard3}>Dashboard</div>
                  </div>
                  <div className={styles.all_sites}>
                    <div className={styles.learn}>Learn</div>
                  </div>
                  <div className={styles.examples}>
                    <div className={styles.examples2}>Examples</div>
                  </div>
                </div>
              </div>
              <div className={styles.person}>
                <img className={styles.ellipse_9} src="/images/home/ellipse-90.png" />
                <svg
                  className={styles.chevron_down3}
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 7.5L10 12.5L15 7.5"
                    stroke="#272727"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
            <div className={styles.nav_menu}>
              <div className={styles.person2}>
                <img className={styles.ellipse_9} src="/images/home/ellipse-91.png" />
                <div className={styles.jane_cooper}>Jane Cooper</div>
              </div>
              <div className={styles.menu}>
                <div className={styles.menu2}>
                  <svg className={styles._3_layers} width="20" height="20"></svg>
                  <div className={styles.all_sites2}>All sites</div>
                </div>
                <div className={styles.nav}>
                  <div className={styles.menu2}>
                    <svg className={styles.settings3} width="20" height="20"></svg>
                    <div className={styles.all_sites2}>Plans</div>
                  </div>
                  <div className={styles.menu3}>
                    <svg className={styles.bar_chart_22} width="20" height="20"></svg>
                    <div className={styles.all_sites2}>Dashboard</div>
                  </div>
                  <div className={styles.menu2}>
                    <svg className={styles.settings4} width="20" height="20"></svg>
                    <div className={styles.all_sites2}>Billings</div>
                  </div>
                  <div className={styles.menu2}>
                    <svg className={styles.settings5} width="20" height="20"></svg>
                    <div className={styles.all_sites2}>Settings</div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.frame_252}>
              <div className={styles.button_group3}>
                <div className={styles.button_group_base6}>
                  <div className={styles.text6}>12 month</div>
                </div>
                <div className={styles.button_group_base2}>
                  <div className={styles.top_line3}></div>
                  <div className={styles.content13}>
                    <div className={styles.text6}>30 days</div>
                  </div>
                  <div className={styles.bottom_line3}></div>
                </div>
                <div className={styles.divider3}></div>
                <div className={styles.button_group_base7}>
                  <div className={styles.text6}>7 days</div>
                </div>
              </div>
              <div className={styles.action4}>
                <div className={styles.date}>
                  <div className={styles.btn}>
                    <svg
                      className={styles.calendar}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.3333 1.66406V4.9974M6.66667 1.66406V4.9974M2.5 8.33073H17.5M4.16667 3.33073H15.8333C16.7538 3.33073 17.5 4.07692 17.5 4.9974V16.6641C17.5 17.5845 16.7538 18.3307 15.8333 18.3307H4.16667C3.24619 18.3307 2.5 17.5845 2.5 16.6641V4.9974C2.5 4.07692 3.24619 3.33073 4.16667 3.33073Z"
                        stroke="#344054"
                        stroke-width="1.67"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <div className={styles.text7}>Select dates</div>
                  </div>
                </div>
                <div className={styles.frame_233}>
                  <svg
                    className={styles.share}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.66699 8.5026V13.8359C2.66699 14.1896 2.80747 14.5287 3.05752 14.7787C3.30756 15.0288 3.6467 15.1693 4.00033 15.1693H12.0003C12.3539 15.1693 12.6931 15.0288 12.9431 14.7787C13.1932 14.5287 13.3337 14.1896 13.3337 13.8359V8.5026M10.667 4.5026L8.00033 1.83594M8.00033 1.83594L5.33366 4.5026M8.00033 1.83594V10.5026"
                      stroke="#6C4ADE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div className={styles.share2}>Share</div>
                </div>
                <div className={styles.frame_243}>
                  <svg
                    className={styles.download3}
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 10.5V13.1667C14 13.5203 13.8595 13.8594 13.6095 14.1095C13.3594 14.3595 13.0203 14.5 12.6667 14.5H3.33333C2.97971 14.5 2.64057 14.3595 2.39052 14.1095C2.14048 13.8594 2 13.5203 2 13.1667V10.5M4.66667 7.16667L8 10.5M8 10.5L11.3333 7.16667M8 10.5V2.5"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div className={styles.export_review}>Export review</div>
                </div>
              </div>
            </div>
            <div className={styles.cintent}>
              <div className={styles.frame_1522}>
                <div className={styles.frame_147}>
                  <div className={styles.views}>Views</div>
                  <svg className={styles.more_vertical} width="24" height="24"></svg>
                </div>
                <div className={styles.frame_151}>
                  <div className={styles.frame_150}>
                    <div className={styles._8_8_k}>8.8k</div>
                    <div className={styles.frame_148}>
                      <div className={styles.frame_149}>
                        <svg className={styles.arrow_upward} width="20" height="20"></svg>
                        <div className={styles._12}>12%</div>
                      </div>
                      <div className={styles.vs_last_month}>vs last month</div>
                    </div>
                  </div>
                  <svg
                    className={styles.group_14}
                    width="123.33334350585938"
                    height="80.13752746582031"
                  ></svg>
                </div>
              </div>
              <div className={styles.frame_256}>
                <div className={styles.frame_147}>
                  <div className={styles.sessions}>Sessions</div>
                  <svg
                    className={styles.more_vertical2}
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.6667 13C13.219 13 13.6667 12.5523 13.6667 12C13.6667 11.4477 13.219 11 12.6667 11C12.1144 11 11.6667 11.4477 11.6667 12C11.6667 12.5523 12.1144 13 12.6667 13Z"
                      stroke="#4541E5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.6667 6C13.219 6 13.6667 5.55228 13.6667 5C13.6667 4.44772 13.219 4 12.6667 4C12.1144 4 11.6667 4.44772 11.6667 5C11.6667 5.55228 12.1144 6 12.6667 6Z"
                      stroke="#4541E5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12.6667 20C13.219 20 13.6667 19.5523 13.6667 19C13.6667 18.4477 13.219 18 12.6667 18C12.1144 18 11.6667 18.4477 11.6667 19C11.6667 19.5523 12.1144 20 12.6667 20Z"
                      stroke="#4541E5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className={styles.frame_151}>
                  <div className={styles.frame_150}>
                    <div className={styles._8_8_k}>8.8k</div>
                    <div className={styles.frame_148}>
                      <div className={styles.frame_149}>
                        <svg className={styles.arrow_upward3} width="20" height="20"></svg>
                        <div className={styles._122}>12%</div>
                      </div>
                      <div className={styles.vs_last_month}>vs last month</div>
                    </div>
                  </div>
                  <svg
                    className={styles.group_142}
                    width="125"
                    height="82"
                    viewBox="0 0 125 82"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M124 81.1375C118.477 79.176 107.32 70.3964 99.1484 59.5609C85.2433 41.1227 79.3262 39.5535 72.5216 42.6919C65.717 45.8303 56.8414 70.5448 42.3445 59.5609C27.8477 48.577 19.268 -1.63766 0.333375 1.10846"
                      stroke="#ED4F45"
                    />
                    <path
                      d="M99.1484 59.5609C107.32 70.3964 118.477 79.176 124 81.1375H0.333374V1.10846C19.268 -1.63766 27.8477 48.577 42.3445 59.5609C56.8414 70.5448 65.717 45.8303 72.5216 42.6919C79.3262 39.5535 85.2433 41.1227 99.1484 59.5609Z"
                      fill="url(#paint0_linear_593_51232)"
                      fill-opacity="0.6"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_593_51232"
                        x1="73.705"
                        y1="-15.0844"
                        x2="53.7898"
                        y2="79.3713"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#EF6B62" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
              <div className={styles.frame_257}>
                <div className={styles.frame_147}>
                  <div className={styles.session_duration}>Session duration</div>
                  <svg
                    className={styles.more_vertical3}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.9999 13C12.5522 13 12.9999 12.5523 12.9999 12C12.9999 11.4477 12.5522 11 11.9999 11C11.4477 11 10.9999 11.4477 10.9999 12C10.9999 12.5523 11.4477 13 11.9999 13Z"
                      stroke="#4541E5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.9999 6C12.5522 6 12.9999 5.55228 12.9999 5C12.9999 4.44772 12.5522 4 11.9999 4C11.4477 4 10.9999 4.44772 10.9999 5C10.9999 5.55228 11.4477 6 11.9999 6Z"
                      stroke="#4541E5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.9999 20C12.5522 20 12.9999 19.5523 12.9999 19C12.9999 18.4477 12.5522 18 11.9999 18C11.4477 18 10.9999 18.4477 10.9999 19C10.9999 19.5523 11.4477 20 11.9999 20Z"
                      stroke="#4541E5"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className={styles.frame_151}>
                  <div className={styles.frame_150}>
                    <div className={styles._12_h}>12h</div>
                    <div className={styles.frame_148}>
                      <div className={styles.frame_149}>
                        <svg
                          className={styles.arrow_upward5}
                          width="21"
                          height="21"
                          viewBox="0 0 21 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_593_51244"
                            style={{maskType: 'alpha'}}
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="21"
                            height="21"
                          >
                            <rect
                              x="0.666626"
                              y="0.136719"
                              width="20"
                              height="20"
                              fill="#D9D9D9"
                            />
                          </mask>
                          <g mask="url(#mask0_593_51244)">
                            <path
                              d="M9.91663 16.1367V7.01172L5.72913 11.1992L4.66663 10.1367L10.6666 4.13672L16.6666 10.1367L15.6041 11.1992L11.4166 7.01172V16.1367H9.91663Z"
                              fill="#51B467"
                            />
                          </g>
                        </svg>

                        <div className={styles._12}>12%</div>
                      </div>
                      <div className={styles.vs_last_month}>vs last month</div>
                    </div>
                  </div>
                  <svg
                    className={styles.group_143}
                    width="125"
                    height="82"
                    viewBox="0 0 125 82"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.666626 81.1375C6.17433 79.176 17.3018 70.3964 25.4513 59.5609C39.3189 41.1227 45.22 39.5535 52.0063 42.6919C58.7926 45.8303 67.6443 70.5448 82.102 59.5609C96.5598 48.577 105.116 -1.63766 124 1.10846"
                      stroke="#51B467"
                    />
                    <path
                      d="M25.4513 59.5609C17.3018 70.3964 6.17433 79.176 0.666626 81.1375H124V1.10846C105.116 -1.63766 96.5598 48.577 82.102 59.5609C67.6443 70.5448 58.7926 45.8303 52.0063 42.6919C45.22 39.5535 39.3189 41.1227 25.4513 59.5609Z"
                      fill="url(#paint0_linear_593_51249)"
                      fill-opacity="0.6"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_593_51249"
                        x1="50.8261"
                        y1="-15.0844"
                        x2="70.7905"
                        y2="79.3496"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#51B467" />
                        <stop offset="1" stop-color="white" stop-opacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            <div className={styles.frame_134}>
              <div className={styles.frame_1432}>
                <div className={styles.frame_144}>
                  <div className={styles.world_visitors}>World visitors</div>
                  <div className={styles.frame_244}>
                    <svg
                      className={styles.lab_profile}
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <mask
                        id="mask0_593_51257"
                        style={{maskType: 'alpha'}}
                        maskUnits="userSpaceOnUse"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                      >
                        <rect width="20" height="20" fill="#D9D9D9" />
                      </mask>
                      <g mask="url(#mask0_593_51257)">
                        <path
                          d="M7 10V8.5H13V10H7ZM7 7V5.5H13V7H7ZM5.5 12H11.25C11.6108 12 11.9468 12.076 12.2578 12.2279C12.5689 12.3798 12.8302 12.5955 13.0417 12.875L14.5 14.7917V3.5H5.5V12ZM5.5 16.5H13.9167L11.863 13.797C11.7877 13.6962 11.6944 13.6215 11.5833 13.5729C11.4722 13.5243 11.3611 13.5 11.25 13.5H5.5V16.5ZM14.4992 18H5.4941C5.08137 18 4.72917 17.8531 4.4375 17.5594C4.14583 17.2656 4 16.9125 4 16.5V3.5C4 3.0875 4.14696 2.73438 4.44088 2.44063C4.73479 2.14688 5.08811 2 5.50083 2H14.5059C14.9186 2 15.2708 2.14688 15.5625 2.44063C15.8542 2.73438 16 3.0875 16 3.5V16.5C16 16.9125 15.853 17.2656 15.5591 17.5594C15.2652 17.8531 14.9119 18 14.4992 18Z"
                          fill="#1C1B1F"
                        />
                      </g>
                    </svg>

                    <div className={styles.real_time_report}>Real time report</div>
                  </div>
                </div>
              </div>
              <div className={styles.frame_1422}>
                <div className={styles.frame_135}>
                  <svg
                    className={styles.g_10}
                    width="226"
                    height="23"
                    viewBox="0 0 226 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M36.2087 21.3359C36.2087 22.4058 35.3414 23.2734 34.2714 23.2734C33.2015 23.2734 32.3335 22.4058 32.3335 21.3359C32.3335 20.2659 33.2015 19.3983 34.2714 19.3983C35.3414 19.3983 36.2087 20.2659 36.2087 21.3359Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M31.486 16.6093C31.486 17.6793 30.6188 18.5469 29.5488 18.5469C28.4788 18.5469 27.6108 17.6793 27.6108 16.6093C27.6108 15.5393 28.4788 14.6717 29.5488 14.6717C30.6188 14.6717 31.486 15.5393 31.486 16.6093Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M22.0397 11.8866C22.0397 12.9566 21.1725 13.8242 20.1025 13.8242C19.0325 13.8242 18.1646 12.9566 18.1646 11.8866C18.1646 10.8167 19.0325 9.94904 20.1025 9.94904C21.1725 9.94904 22.0397 10.8167 22.0397 11.8866Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M17.3161 11.8866C17.3161 12.9566 16.4488 13.8242 15.3789 13.8242C14.3089 13.8242 13.4409 12.9566 13.4409 11.8866C13.4409 10.8167 14.3089 9.94904 15.3789 9.94904C16.4488 9.94904 17.3161 10.8167 17.3161 11.8866Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M12.5934 7.16398C12.5934 8.23395 11.7262 9.10156 10.6562 9.10156C9.58623 9.10156 8.71826 8.23395 8.71826 7.16398C8.71826 6.094 9.58623 5.22639 10.6562 5.22639C11.7262 5.22639 12.5934 6.094 12.5934 7.16398Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M45.655 21.3359C45.655 22.4058 44.7877 23.2734 43.7177 23.2734C42.6478 23.2734 41.7798 22.4058 41.7798 21.3359C41.7798 20.2659 42.6478 19.3983 43.7177 19.3983C44.7877 19.3983 45.655 20.2659 45.655 21.3359Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M92.8864 21.3359C92.8864 22.4058 92.0192 23.2734 90.9492 23.2734C89.8792 23.2734 89.0112 22.4058 89.0112 21.3359C89.0112 20.2659 89.8792 19.3983 90.9492 19.3983C92.0192 19.3983 92.8864 20.2659 92.8864 21.3359Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M83.4401 16.6093C83.4401 17.6793 82.5729 18.5469 81.5029 18.5469C80.4329 18.5469 79.5649 17.6793 79.5649 16.6093C79.5649 15.5393 80.4329 14.6717 81.5029 14.6717C82.5729 14.6717 83.4401 15.5393 83.4401 16.6093Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M88.1637 16.6093C88.1637 17.6793 87.2965 18.5469 86.2265 18.5469C85.1565 18.5469 84.2886 17.6793 84.2886 16.6093C84.2886 15.5393 85.1565 14.6717 86.2265 14.6717C87.2965 14.6717 88.1637 15.5393 88.1637 16.6093Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M88.1637 11.8866C88.1637 12.9566 87.2965 13.8242 86.2265 13.8242C85.1565 13.8242 84.2886 12.9566 84.2886 11.8866C84.2886 10.8167 85.1565 9.94904 86.2265 9.94904C87.2965 9.94904 88.1637 10.8167 88.1637 11.8866Z"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M97.61 11.8866C97.61 12.9566 96.7428 13.8242 95.6728 13.8242C94.6028 13.8242 93.7349 12.9566 93.7349 11.8866C93.7349 10.8167 94.6028 9.94904 95.6728 9.94904C96.7428 9.94904 97.61 10.8167 97.61 11.8866Z"
                      fill="#CFCFCF"
                    />
                  </svg>

                  <div className={styles.group_12}>
                    <div className={styles.ellipse_242}></div>
                    <div className={styles.ellipse_252}></div>
                  </div>
                  <div className={styles.group_5}>
                    <div className={styles.ellipse_243}></div>
                    <div className={styles.ellipse_253}></div>
                  </div>
                  <div className={styles.group_4}>
                    <div className={styles.ellipse_244}></div>
                    <div className={styles.ellipse_254}></div>
                  </div>
                  <img className={styles.image_1} src="/images/home/image_10.png" />
                  <div className={styles.map}>
                    <div className={styles.circle}></div>
                    <div className={styles.circle2}></div>
                    <div className={styles.circle3}></div>
                  </div>
                  <div className={styles.map2}>
                    <div className={styles.circle}></div>
                    <div className={styles.circle2}></div>
                    <div className={styles.circle3}></div>
                  </div>
                  <div className={styles.map3}>
                    <div className={styles.circle}></div>
                    <div className={styles.circle2}></div>
                    <div className={styles.circle3}></div>
                  </div>
                  <div className={styles.map4}>
                    <div className={styles.circle}></div>
                    <div className={styles.circle2}></div>
                    <div className={styles.circle3}></div>
                  </div>
                  <div className={styles.map5}>
                    <div className={styles.circle}></div>
                    <div className={styles.circle2}></div>
                    <div className={styles.circle3}></div>
                  </div>
                  <div className={styles.map6}>
                    <div className={styles.circle}></div>
                    <div className={styles.circle2}></div>
                    <div className={styles.circle3}></div>
                  </div>
                  <div className={styles.group_13}>
                    <svg
                      className={styles.union36}
                      width="10"
                      height="8"
                      viewBox="0 0 10 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_d_593_54088)">
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M-150 20C-158.837 20 -166 27.1634 -166 36V76C-166 84.8366 -158.837 92 -150 92H-97.0006L-91.557 98.9784C-90.7563 100.005 -89.2038 100.005 -88.4031 98.9784L-82.9595 92H-30.0005C-21.1639 92 -14.0005 84.8366 -14.0005 76V36C-14.0005 27.1634 -21.1639 20 -30.0005 20H-150Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <filter
                          id="filter0_d_593_54088"
                          x="-190"
                          y="0"
                          width="200"
                          height="127.75"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                            result="hardAlpha"
                          />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="12" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow_593_54088"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_dropShadow_593_54088"
                            result="shape"
                          />
                        </filter>
                      </defs>
                    </svg>

                    <div className={styles.frame_146}>
                      <img className={styles.ellipse_232} src="/images/home/ellipse-230.png" />
                      <div className={styles.frame_145}>
                        <div className={styles.london_uk}>London, UK</div>
                        <div className={styles._523_users}>523 users</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.frame_136}>
                  <div className={styles._8_8_k}>8.8k</div>
                  <div className={styles.frame_141}>
                    <div className={styles.frame_137}>
                      <img className={styles.ellipse_232} src="/images/home/ellipse-231.png" />
                      <div className={styles.frame_138}>
                        <div className={styles.united_states}>United States</div>
                        <div className={styles.frame_140}>
                          <div className={styles.frame_139}>
                            <div className={styles.rectangle_28}></div>
                            <div className={styles.rectangle_27}></div>
                          </div>
                          <div className={styles._55}>55%</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frame_1382}>
                      <img className={styles.ellipse_232} src="/images/home/ellipse-232.png" />
                      <div className={styles.frame_138}>
                        <div className={styles.canada}>Canada</div>
                        <div className={styles.frame_140}>
                          <div className={styles.frame_139}>
                            <div className={styles.rectangle_28}></div>
                            <div className={styles.rectangle_272}></div>
                          </div>
                          <div className={styles._45}>45%</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frame_1392}>
                      <img className={styles.ellipse_232} src="/images/home/ellipse_233.png" />
                      <div className={styles.frame_138}>
                        <div className={styles.australia}>Australia</div>
                        <div className={styles.frame_140}>
                          <div className={styles.frame_1393}>
                            <div className={styles.rectangle_282}></div>
                            <div className={styles.rectangle_273}></div>
                          </div>
                          <div className={styles._25}>25%</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frame_1402}>
                      <img className={styles.ellipse_232} src="/images/home/ellipse_234.png" />
                      <div className={styles.frame_138}>
                        <div className={styles.united_kingdom}>United Kingdom</div>
                        <div className={styles.frame_140}>
                          <div className={styles.frame_139}>
                            <div className={styles.rectangle_28}></div>
                            <div className={styles.rectangle_274}></div>
                          </div>
                          <div className={styles._15}>15%</div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.frame_1412}>
                      <img className={styles.ellipse_232} src="/images/home/ellipse_235.png" />
                      <div className={styles.frame_138}>
                        <div className={styles.ireland}>Ireland</div>
                        <div className={styles.frame_140}>
                          <div className={styles.frame_139}>
                            <div className={styles.rectangle_28}></div>
                            <div className={styles.rectangle_275}></div>
                          </div>
                          <div className={styles._103}>10%</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cintent2}>
            <div className={styles.frame_1523}>
              <div className={styles.frame_147}>
                <div className={styles.views2}>Views</div>
                <svg
                  className={styles.more_vertical4}
                  width="25.569416046142578"
                  height="25.569416046142578"
                ></svg>
              </div>
              <div className={styles.frame_1512}>
                <div className={styles.frame_150}>
                  <div className={styles._8_8_k2}>8.8k</div>
                  <div className={styles.frame_1482}>
                    <div className={styles.frame_1492}>
                      <svg
                        className={styles.arrow_upward7}
                        width="21.30784797668457"
                        height="21.30784797668457"
                      ></svg>
                      <div className={styles._123}>12%</div>
                    </div>
                    <div className={styles.vs_last_month2}>vs last month</div>
                  </div>
                </div>
                <svg
                  className={styles.group_144}
                  width="131.21832275390625"
                  height="85.3779067993164"
                ></svg>
              </div>
            </div>
            <div className={styles.frame_2562}>
              <div className={styles.frame_147}>
                <div className={styles.sessions2}>Sessions</div>
                <svg
                  className={styles.more_vertical5}
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3842 14.4206C13.9726 14.4206 14.4496 13.9436 14.4496 13.3552C14.4496 12.7668 13.9726 12.2898 13.3842 12.2898C12.7958 12.2898 12.3188 12.7668 12.3188 13.3552C12.3188 13.9436 12.7958 14.4206 13.3842 14.4206Z"
                    stroke="#4541E5"
                    stroke-width="1.59809"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.3842 6.96282C13.9726 6.96282 14.4496 6.48582 14.4496 5.89742C14.4496 5.30902 13.9726 4.83203 13.3842 4.83203C12.7958 4.83203 12.3188 5.30902 12.3188 5.89742C12.3188 6.48582 12.7958 6.96282 13.3842 6.96282Z"
                    stroke="#4541E5"
                    stroke-width="1.59809"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.3842 21.8783C13.9726 21.8783 14.4496 21.4013 14.4496 20.8129C14.4496 20.2245 13.9726 19.7475 13.3842 19.7475C12.7958 19.7475 12.3188 20.2245 12.3188 20.8129C12.3188 21.4013 12.7958 21.8783 13.3842 21.8783Z"
                    stroke="#4541E5"
                    stroke-width="1.59809"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className={styles.frame_1512}>
                <div className={styles.frame_150}>
                  <div className={styles._8_8_k2}>8.8k</div>
                  <div className={styles.frame_1482}>
                    <div className={styles.frame_1492}>
                      <svg
                        className={styles.arrow_upward9}
                        width="21.30784797668457"
                        height="21.30784797668457"
                      ></svg>
                      <div className={styles._124}>12%</div>
                    </div>
                    <div className={styles.vs_last_month2}>vs last month</div>
                  </div>
                </div>
                <svg
                  className={styles.group_145}
                  width="133"
                  height="88"
                  viewBox="0 0 133 88"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M132.704 86.5615C126.82 84.4717 114.933 75.118 106.227 63.5739C91.413 43.93 85.109 42.2582 77.8594 45.6018C70.6098 48.9455 61.1538 75.2761 45.709 63.5739C30.2642 51.8718 21.1234 -1.62655 0.950655 1.29914"
                    stroke="#ED4F45"
                    stroke-width="1.06539"
                  />
                  <path
                    d="M106.227 63.5739C114.933 75.118 126.82 84.4717 132.704 86.5615H0.950653V1.29914C21.1234 -1.62655 30.2642 51.8718 45.709 63.5739C61.1538 75.2761 70.6098 48.9455 77.8594 45.6018C85.109 42.2582 91.413 43.93 106.227 63.5739Z"
                    fill="url(#paint0_linear_593_54176)"
                    fill-opacity="0.6"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_593_54176"
                      x1="79.1202"
                      y1="-15.9526"
                      x2="57.9028"
                      y2="84.6798"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#EF6B62" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className={styles.frame_2572}>
              <div className={styles.frame_147}>
                <div className={styles.session_duration2}>Session duration</div>
                <svg
                  className={styles.more_vertical6}
                  width="27"
                  height="27"
                  viewBox="0 0 27 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.6458 14.4206C14.2342 14.4206 14.7112 13.9436 14.7112 13.3552C14.7112 12.7668 14.2342 12.2898 13.6458 12.2898C13.0574 12.2898 12.5804 12.7668 12.5804 13.3552C12.5804 13.9436 13.0574 14.4206 13.6458 14.4206Z"
                    stroke="#4541E5"
                    stroke-width="1.59809"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.6458 6.96282C14.2342 6.96282 14.7112 6.48582 14.7112 5.89742C14.7112 5.30902 14.2342 4.83203 13.6458 4.83203C13.0574 4.83203 12.5804 5.30902 12.5804 5.89742C12.5804 6.48582 13.0574 6.96282 13.6458 6.96282Z"
                    stroke="#4541E5"
                    stroke-width="1.59809"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M13.6458 21.8783C14.2342 21.8783 14.7112 21.4013 14.7112 20.8129C14.7112 20.2245 14.2342 19.7475 13.6458 19.7475C13.0574 19.7475 12.5804 20.2245 12.5804 20.8129C12.5804 21.4013 13.0574 21.8783 13.6458 21.8783Z"
                    stroke="#4541E5"
                    stroke-width="1.59809"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div className={styles.frame_1512}>
                <div className={styles.frame_150}>
                  <div className={styles._12_h2}>12h</div>
                  <div className={styles.frame_1482}>
                    <div className={styles.frame_1492}>
                      <svg
                        className={styles.arrow_upward11}
                        width="22"
                        height="22"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_593_54188"
                          style={{maskType: 'alpha'}}
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="22"
                          height="22"
                        >
                          <rect
                            x="0.0924988"
                            y="0.253906"
                            width="21.3078"
                            height="21.3078"
                            fill="#D9D9D9"
                          />
                        </mask>
                        <g mask="url(#mask0_593_54188)">
                          <path
                            d="M9.94737 17.3003V7.57863L5.48604 12.04L4.35406 10.908L10.7464 4.51562L17.1388 10.908L16.0068 12.04L11.5455 7.57863V17.3003H9.94737Z"
                            fill="#51B467"
                          />
                        </g>
                      </svg>

                      <div className={styles._123}>12%</div>
                    </div>
                    <div className={styles.vs_last_month2}>vs last month</div>
                  </div>
                </div>
                <svg
                  className={styles.group_146}
                  width="132"
                  height="88"
                  viewBox="0 0 132 88"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.212219 86.5615C6.07205 84.4717 17.9109 75.118 26.5814 63.5739C41.3357 43.93 47.614 42.2582 54.8342 45.6018C62.0543 48.9455 71.4719 75.2761 86.854 63.5739C102.236 51.8718 111.34 -1.62655 131.431 1.29914"
                    stroke="#51B467"
                    stroke-width="1.06539"
                  />
                  <path
                    d="M26.5814 63.5739C17.9109 75.118 6.07205 84.4717 0.212219 86.5615H131.431V1.29914C111.34 -1.62655 102.236 51.8718 86.854 63.5739C71.4719 75.2761 62.0543 48.9455 54.8342 45.6018C47.614 42.2582 41.3357 43.93 26.5814 63.5739Z"
                    fill="url(#paint0_linear_593_54193)"
                    fill-opacity="0.6"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_593_54193"
                      x1="53.5785"
                      y1="-15.9526"
                      x2="74.8751"
                      y2="84.6448"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#51B467" />
                      <stop offset="1" stop-color="white" stop-opacity="0" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Home;

