import React from 'react';

import './Profile.css';

interface ProfileProps{

}

interface ProfileState{

}

export  default class Profile extends React.Component<ProfileProps, ProfileState> {

    render(){
        return(
            <div>
                <h1>Profile</h1>
            </div>
        );
    }
}