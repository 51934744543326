import React, { useRef, useEffect, useState } from "react";

import  styles from "./Editor.module.css";

interface EditorProps {}

interface EditorState {}

const sections = ["head", "hero", "features", "services", "footer"];
const pageserviceurl = "http://localhost:4000/api/v1/Page";

function extractSection(div: HTMLElement, section: string): HTMLElement | null {
  switch (section) {
    case "head":
      // Return the div section with id = "head"
      return div.querySelector('div[id="head"]');
    case "nav":
      // Return the nav tag section
      return div.querySelector("nav");
    default:
      return null; // or throw an error if an unexpected section is provided
  }
}

const fetchSection = async (section: string) => {
  const url = `${pageserviceurl}?prompt=create a website for daycare&section=${section}`;
  console.log("calling:" + url);
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.text();
  });
};


const Editor: React.FC<EditorProps> = () => {


    const iframeRef = useRef<HTMLIFrameElement | null>(null);

    const [contentDocumentBody, setContentDocumentBody] =
    useState<HTMLElement | null>(null);
    const [contentDocumentHead, setContentDocumentHead] =
    useState<HTMLElement | null>(null);

    const onIframeLoad = () => {
        if (!iframeRef.current) return;
      
        const doc = iframeRef.current.contentDocument;
        if (doc && doc.body) {
          setContentDocumentBody(doc.body);
        }
      };

      
  useEffect(() => {
    if (!contentDocumentBody) return;
    if (!contentDocumentHead) return;

    // Your logic to fetch and populate iframe data can go here.
    // This is just a basic outline and you can adjust as needed.

    const renderSectionsSequential = async () => {
      for (const section of sections) {
        try {
          const content = await fetchSection(section);
          const div = document.createElement("div");
          div.innerHTML = content;
          contentDocumentBody.appendChild(div);
          // Scroll to the bottom of the iframe
          if (iframeRef.current && iframeRef.current.contentWindow) {
            const contentDocumentBody =
              iframeRef.current?.contentDocument?.body;
            if (contentDocumentBody) {
              iframeRef.current?.contentWindow?.scrollTo(
                0,
                contentDocumentBody.scrollHeight
              );
            }
          }
        } catch (error) {
          console.error(`Error fetching section ${section}:`, error);

          let errorMessageDiv = document.getElementById("error-message");

          if (!errorMessageDiv) {
            errorMessageDiv = document.createElement("div");
            errorMessageDiv.id = "error-message";
            document.body.appendChild(errorMessageDiv);
          }

          const h2 = document.createElement("h2");
          h2.innerText = `Error fetching section ${section}. Please try again later.`;
          h2.style.color = "red";

          errorMessageDiv.appendChild(h2);
        }
      }
    };

    const renderSections = async () => {
      try {
        // Start all fetches concurrently and get a list of promises.
        const fetchPromises = sections.map(async (section) => {
          const content = await fetchSection(section);
          const div = document.createElement("div") as HTMLDivElement;
          div.innerHTML = content;
          return { [section]: div };
        });

        // Wait for all fetches to complete.
        const divs = await Promise.all(fetchPromises);

        // Iterate through each object in the divs array
        for (const divObj of divs) {
          for (const section in divObj) {
            const div = divObj[section] as HTMLDivElement;

            //  if (div instanceof Node) {
            if (section.trim().toLowerCase() === "head") {
              console.log("Adding elements to head.");
              const headSection = extractSection(div, "head");
              const navSection = extractSection(div, "nav");
              if (headSection) contentDocumentHead.appendChild(headSection);

              if (navSection) contentDocumentBody.appendChild(navSection);
              //contentDocumentHead.appendChild();
            } else contentDocumentBody.appendChild(div);
            // Scroll to the bottom of the iframe
            if (iframeRef.current && iframeRef.current.contentWindow) {
              const contentDocumentBody =
                iframeRef.current?.contentDocument?.body;
              if (contentDocumentBody) {
                iframeRef.current?.contentWindow?.scrollTo(
                  0,
                  contentDocumentBody.scrollHeight
                );
              }
            } else {
              console.log(
                "Expecting the section to be a div element while rendering sections but encountered a non-div element",
                div
              );
            }
          }
        }
      } catch (error) {
        console.error(`Error fetching :`, error);

        let errorMessageDiv = document.getElementById("error-message");

        if (!errorMessageDiv) {
          errorMessageDiv = document.createElement("div");
          errorMessageDiv.id = "error-message";
          contentDocumentBody.appendChild(errorMessageDiv);
        }

        // TODO: Display a proper error message to users when the service is down
        const h2 = document.createElement("h4");
        h2.innerText = `Error fetching from page service using ${pageserviceurl}. Is the service running?.`;
        h2.style.color = "red";

        errorMessageDiv.appendChild(h2);
      }
    };

   // renderSections();
  }, [contentDocumentBody]);

  useEffect(() => {
    console.log("Preview called");
  }, []);

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handleMenuClick = (index: number) => {
      if (index !== activeIndex) {
          setActiveIndex(index);
      }
  };

  return (
    <div className={styles.editor_container}> 
      <div className={styles.editor_menu_panel}>
        <div className={styles.editor_menu_items_contianer}>
            
        <div className={`${styles.editor_menu_item} ${activeIndex === 0 ? styles.active : ''} ${styles.desktop}`} onClick={() => handleMenuClick(0)}>
        
         <svg
              className={styles.laptop_mac}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_296_127667"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_296_127667)">
                <path
                  d="M1.77867 16.3639C1.40581 16.3639 1.08557 16.2301 0.817941 15.9625C0.550316 15.6949 0.416504 15.3746 0.416504 15.0018H3.58961C3.16866 15.0018 2.81236 14.8559 2.52069 14.5643C2.22902 14.2726 2.08319 13.9163 2.08319 13.4954V4.42487C2.08319 4.00393 2.22902 3.64762 2.52069 3.35596C2.81236 3.06429 3.16866 2.91846 3.58961 2.91846H16.4101C16.831 2.91846 17.1873 3.06429 17.479 3.35596C17.7707 3.64762 17.9165 4.00393 17.9165 4.42487V13.4954C17.9165 13.9163 17.7707 14.2726 17.479 14.5643C17.1873 14.8559 16.831 15.0018 16.4101 15.0018H19.5832C19.5832 15.3746 19.4493 15.6949 19.1817 15.9625C18.9141 16.2301 18.5938 16.3639 18.221 16.3639H1.77867ZM9.99982 15.7389C10.1878 15.7389 10.347 15.6738 10.4774 15.5434C10.6077 15.4131 10.6729 15.2539 10.6729 15.0659C10.6729 14.8778 10.6077 14.7187 10.4774 14.5883C10.347 14.458 10.1878 14.3928 9.99982 14.3928C9.81179 14.3928 9.6526 14.458 9.52225 14.5883C9.39192 14.7187 9.32675 14.8778 9.32675 15.0659C9.32675 15.2539 9.39192 15.4131 9.52225 15.5434C9.6526 15.6738 9.81179 15.7389 9.99982 15.7389ZM3.58959 13.7518H16.41C16.4742 13.7518 16.5329 13.7251 16.5864 13.6717C16.6398 13.6183 16.6665 13.5595 16.6665 13.4954V4.42487C16.6665 4.36076 16.6398 4.30199 16.5864 4.24856C16.5329 4.19515 16.4742 4.16844 16.41 4.16844H3.58959C3.52548 4.16844 3.46671 4.19515 3.41328 4.24856C3.35986 4.30199 3.33315 4.36076 3.33315 4.42487V13.4954C3.33315 13.5595 3.35986 13.6183 3.41328 13.6717C3.46671 13.7251 3.52548 13.7518 3.58959 13.7518Z"
                  className={styles.icon_fill}
                />
              </g>
            </svg>

            <div className={styles.desktop}>Desktop</div>
          </div>
          <div className={`${styles.editor_menu_item} ${activeIndex === 1 ? styles.active : ''} ${styles.tablet}`} onClick={() => handleMenuClick(1)}>
            <svg
              className={styles.tablet_mac}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_296_127672"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_296_127672)">
                <path
                  d="M9.99982 16.891C10.2039 16.891 10.3777 16.8191 10.5214 16.6754C10.6651 16.5317 10.737 16.3579 10.737 16.1538C10.737 15.9498 10.6651 15.7759 10.5214 15.6322C10.3777 15.4885 10.2039 15.4166 9.99982 15.4166C9.79576 15.4166 9.62189 15.4885 9.47819 15.6322C9.3345 15.7759 9.26265 15.9498 9.26265 16.1538C9.26265 16.3579 9.3345 16.5317 9.47819 16.6754C9.62189 16.8191 9.79576 16.891 9.99982 16.891ZM4.42292 18.75C4.00732 18.75 3.65236 18.6028 3.35802 18.3084C3.06368 18.0141 2.9165 17.6591 2.9165 17.2435V2.75642C2.9165 2.34082 3.06368 1.98585 3.35802 1.69152C3.65236 1.39717 4.00732 1.25 4.42292 1.25H15.5767C15.9923 1.25 16.3473 1.39717 16.6416 1.69152C16.936 1.98585 17.0831 2.34082 17.0831 2.75642V17.2435C17.0831 17.6591 16.936 18.0141 16.6416 18.3084C16.3473 18.6028 15.9923 18.75 15.5767 18.75L4.42292 18.75ZM4.16648 14.8077V17.2435C4.16648 17.3183 4.19052 17.3798 4.23861 17.4279C4.28669 17.4759 4.34813 17.5 4.42292 17.5H15.5767C15.6515 17.5 15.7129 17.4759 15.761 17.4279C15.8091 17.3798 15.8332 17.3183 15.8332 17.2435V14.8077H4.16648ZM4.16648 13.5577H15.8332V4.79163H4.16648V13.5577ZM4.16648 3.54167H15.8332V2.75642C15.8332 2.68163 15.8091 2.62019 15.761 2.5721C15.7129 2.52402 15.6515 2.49998 15.5767 2.49998H4.42292C4.34813 2.49998 4.28669 2.52402 4.23861 2.5721C4.19052 2.62019 4.16648 2.68163 4.16648 2.75642V3.54167Z"
                  className={styles.icon_fill}
                />
              </g>
            </svg>

            <div className={styles.tablet}>Tablet</div>
          </div>
          <div className={`${styles.editor_menu_item} ${activeIndex === 2 ? styles.active : ''} ${styles.phone}`} onClick={() => handleMenuClick(2)}>
            <svg
              className={styles.smartphone}
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <mask
                id="mask0_296_127677"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="20"
                height="20"
              >
                <rect width="20" height="20" fill="#D9D9D9" />
              </mask>
              <g mask="url(#mask0_296_127677)">
                <path
                  d="M6.08991 18.7499C5.66897 18.7499 5.31266 18.6041 5.021 18.3124C4.72933 18.0208 4.5835 17.6645 4.5835 17.2435V2.75642C4.5835 2.33547 4.72933 1.97917 5.021 1.6875C5.31266 1.39583 5.66897 1.25 6.08991 1.25H13.9104C14.3313 1.25 14.6876 1.39583 14.9793 1.6875C15.271 1.97917 15.4168 2.33547 15.4168 2.75642V17.2435C15.4168 17.6645 15.271 18.0208 14.9793 18.3125C14.6876 18.6041 14.3313 18.75 13.9104 18.75L6.08991 18.7499ZM5.83348 16.4583V17.2436C5.83348 17.3077 5.86018 17.3664 5.9136 17.4199C5.96703 17.4733 6.0258 17.5 6.08991 17.5H13.9104C13.9745 17.5 14.0333 17.4733 14.0867 17.4199C14.1401 17.3664 14.1668 17.3077 14.1668 17.2436V16.4583H5.83348ZM5.83348 15.2084H14.1668V4.79165H5.83348V15.2084ZM5.83348 3.54169H14.1668V2.75644C14.1668 2.69233 14.1401 2.63356 14.0867 2.58013C14.0333 2.52671 13.9745 2.5 13.9104 2.5H6.08991C6.0258 2.5 5.96703 2.52671 5.9136 2.58013C5.86018 2.63356 5.83348 2.69233 5.83348 2.75644V3.54169Z"
                  className={styles.icon_fill}
                />
              </g>
            </svg>

            <div className={styles.mobile}>Mobile</div>
          </div>
        </div>
      </div>
        <div   className={`${styles.editor_panel} ${activeIndex === 0 ? styles.desktop_dimensions : ''} ${activeIndex === 1 ? styles.tablet_dimensions : ''} ${activeIndex === 2 ? styles.phone_dimensions : ''} `} >
            <iframe
            name="preview_frame"
            onLoad={onIframeLoad}
            src="http://localhost:4000/api/v1/Section?section=all" // We're not loading any external URL, React will populate it.
            ref={iframeRef}
            style={{
                width: "100%",
                height: "100%",
                border: "none",

            }}
            />
        </div>
      </div>
  );
};


export default Editor;