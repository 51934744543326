import React from 'react';
import styles from './ForgotPassword.module.css';
import { useNavigate } from 'react-router-dom';

interface ForgotPasswordProps {}
interface SignInProps {}

const ForgotPassword: React.FC<ForgotPasswordProps> = () => {

  const navigate = useNavigate();

  const navigateToSignup = () => {
      navigate('/SignUp');
  }

    return (
        <>
    <div className={styles.forgot_password}>
      <div className={styles.decorations}>
        <svg
          className={styles.polygon_1}
          width="31"
          height="31"
          viewBox="0 0 31 31"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39675)">
            <path
              d="M21.787 3.99272L26.8844 26.6529L4.71145 19.7373L21.787 3.99272Z"
              fill="white"
              fill-opacity="0.3"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39675"
              x="0.711449"
              y="-0.0078125"
              width="30.173"
              height="30.6602"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2"
                result="effect1_foregroundBlur_885_39675"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.polygon_3}
          width="43"
          height="42"
          viewBox="0 0 43 42"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39676)">
            <path
              d="M12.7038 4.75665L38.9417 25.666L4.81373 37.3661L12.7038 4.75665Z"
              fill="white"
              fill-opacity="0.6"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39676"
              x="0.813721"
              y="0.757812"
              width="42.128"
              height="40.6094"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2"
                result="effect1_foregroundBlur_885_39676"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.polygon_2}
          width="39"
          height="40"
          viewBox="0 0 39 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39677)">
            <path
              d="M11.4879 8.47888L30.1126 22.3564L8.78196 31.5472L11.4879 8.47888Z"
              fill="white"
              fill-opacity="0.7"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39677"
              x="0.781982"
              y="0.480469"
              width="37.3307"
              height="39.0664"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="4"
                result="effect1_foregroundBlur_885_39677"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.polygon_4}
          width="39"
          height="39"
          viewBox="0 0 39 39"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39678)">
            <path
              d="M23.3425 8.59289L30.8472 30.5735L8.05912 26.0825L23.3425 8.59289Z"
              fill="white"
              fill-opacity="0.7"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39678"
              x="0.059082"
              y="0.59375"
              width="38.7881"
              height="37.9805"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="4"
                result="effect1_foregroundBlur_885_39678"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.star_1}
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39679)">
            <path
              d="M10.7166 6.31915C10.7403 5.39114 11.9057 4.99341 12.4918 5.71331L16.146 10.2018C16.3303 10.4281 16.6042 10.5627 16.896 10.5701L22.682 10.7178C23.61 10.7415 24.0078 11.9068 23.2879 12.4929L18.7994 16.1472C18.5731 16.3314 18.4385 16.6054 18.4311 16.8972L18.2834 22.6832C18.2597 23.6112 17.0943 24.0089 16.5082 23.289L12.854 18.8006C12.6697 18.5742 12.3958 18.4397 12.104 18.4322L6.31799 18.2846C5.38998 18.2609 4.99224 17.0955 5.71214 16.5094L10.2006 12.8552C10.427 12.6709 10.5615 12.3969 10.5689 12.1052L10.7166 6.31915Z"
              fill="#FCFF72"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39679"
              x="0.341705"
              y="0.34375"
              width="28.3166"
              height="28.3164"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2.5"
                result="effect1_foregroundBlur_885_39679"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.star_2}
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39680)">
            <path
              d="M15.8323 5.66746C16.3534 4.8992 17.549 5.19379 17.6535 6.1162L18.3052 11.8673C18.3381 12.1573 18.496 12.4185 18.7375 12.5823L23.5276 15.8312C24.2958 16.3523 24.0012 17.548 23.0788 17.6525L17.3277 18.3041C17.0377 18.337 16.7765 18.4949 16.6127 18.7364L13.3638 23.5265C12.8427 24.2948 11.6471 24.0002 11.5425 23.0778L10.8909 17.3266C10.858 17.0366 10.7001 16.7755 10.4586 16.6116L5.66853 13.3627C4.90026 12.8416 5.19486 11.646 6.11727 11.5415L11.8684 10.8898C12.1584 10.857 12.4196 10.699 12.5834 10.4575L15.8323 5.66746Z"
              fill="#FCFF72"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39680"
              x="0.22876"
              y="0.226562"
              width="28.7385"
              height="28.7383"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2.5"
                result="effect1_foregroundBlur_885_39680"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.line_2}
          width="70"
          height="33"
          viewBox="0 0 70 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39681)">
            <path
              d="M4.99997 24.5195C10.3729 22.8671 11.1329 27.8645 16.5059 26.212C21.8788 24.5596 19.6992 19.9988 25.0721 18.3464C30.4451 16.6939 31.2051 21.6913 36.578 20.0389C41.951 18.3864 39.7713 13.8257 45.1443 12.1732C50.5173 10.5208 51.2772 15.5182 56.6502 13.8657C62.0232 12.2133 59.8435 7.65252 65.2165 6.00008"
              stroke="white"
              stroke-opacity="0.4"
              stroke-width="4"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39681"
              x="0.412048"
              y="0.0898438"
              width="69.3924"
              height="32.4609"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2"
                result="effect1_foregroundBlur_885_39681"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.line_3}
          width="72"
          height="21"
          viewBox="0 0 72 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39682)">
            <path
              d="M4.91907 6.38672C10.5287 6.74858 9.46575 11.6904 15.0754 12.0523C20.6851 12.4141 20.2658 7.37668 25.8755 7.73854C31.4852 8.1004 30.4222 13.0422 36.0319 13.4041C41.6416 13.7659 41.2223 8.7285 46.832 9.09036C52.4416 9.45222 51.3786 14.394 56.9883 14.7559C62.598 15.1178 62.1787 10.0803 67.7884 10.4422"
              stroke="white"
              stroke-opacity="0.4"
              stroke-width="4"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39682"
              x="0.790283"
              y="0.390625"
              width="71.1268"
              height="20.3828"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2"
                result="effect1_foregroundBlur_885_39682"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.line_4}
          width="72"
          height="26"
          viewBox="0 0 72 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39683)">
            <path
              d="M4.85059 6.22656C10.4055 7.08802 8.90538 11.9151 14.4603 12.7766C20.0153 13.6381 20.0476 8.58331 25.6025 9.44476C31.1575 10.3062 29.6573 15.1333 35.2123 15.9948C40.7672 16.8563 40.7995 11.8015 46.3545 12.663C51.9094 13.5244 50.4093 18.3515 55.9642 19.213C61.5191 20.0745 61.5515 15.0197 67.1064 15.8812"
              stroke="white"
              stroke-opacity="0.4"
              stroke-width="4"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39683"
              x="0.544067"
              y="0.25"
              width="70.8688"
              height="25.0625"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2"
                result="effect1_foregroundBlur_885_39683"
              />
            </filter>
          </defs>
        </svg>

        <div className={styles.ellipse_19}></div>
        <div className={styles.ellipse_20}></div>
        <svg
          className={styles.line_5}
          width="70"
          height="33"
          viewBox="0 0 70 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39686)">
            <path
              d="M4.99997 24.5195C10.3729 22.8671 11.1329 27.8645 16.5059 26.212C21.8788 24.5596 19.6992 19.9988 25.0721 18.3464C30.4451 16.6939 31.2051 21.6913 36.578 20.0389C41.951 18.3864 39.7713 13.8257 45.1443 12.1732C50.5173 10.5208 51.2772 15.5182 56.6502 13.8657C62.0232 12.2133 59.8435 7.65252 65.2165 6.00008"
              stroke="white"
              stroke-opacity="0.4"
              stroke-width="4"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39686"
              x="0.412048"
              y="0.0898438"
              width="69.3924"
              height="32.4609"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2"
                result="effect1_foregroundBlur_885_39686"
              />
            </filter>
          </defs>
        </svg>

        <svg
          className={styles.star_3}
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_f_885_39687)">
            <path
              d="M12.8408 6.14367C13.049 5.23901 14.2703 5.08156 14.7011 5.90384L17.3873 11.0307C17.5227 11.2892 17.7644 11.4756 18.0488 11.5411L23.6893 12.8392C24.5939 13.0474 24.7514 14.2686 23.9291 14.6995L18.8023 17.3857C18.5438 17.5211 18.3573 17.7627 18.2919 18.0472L16.9938 23.6876C16.7856 24.5923 15.5643 24.7498 15.1335 23.9275L12.4473 18.8007C12.3118 18.5421 12.0702 18.3557 11.7858 18.2902L6.14529 16.9922C5.24063 16.784 5.08318 15.5627 5.90546 15.1319L11.0323 12.4457C11.2908 12.3102 11.4773 12.0686 11.5427 11.7842L12.8408 6.14367Z"
              fill="#FCFF72"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_885_39687"
              x="0.369263"
              y="0.367188"
              width="29.0961"
              height="29.0977"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="2.5"
                result="effect1_foregroundBlur_885_39687"
              />
            </filter>
          </defs>
        </svg>

        <div className={styles.ellipse_21}></div>
        <div className={styles.frame_79}>
          <div className={styles.frame_74}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
          <div className={styles.frame_75}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
          <div className={styles.frame_76}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
          <div className={styles.frame_77}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
          <div className={styles.frame_78}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
          <div className={styles.frame_792}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
          <div className={styles.frame_80}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
          <div className={styles.frame_81}>
            <div className={styles.ellipse_12}></div>
            <div className={styles.ellipse_18}></div>
            <div className={styles.ellipse_17}></div>
            <div className={styles.ellipse_16}></div>
            <div className={styles.ellipse_15}></div>
            <div className={styles.ellipse_14}></div>
            <div className={styles.ellipse_13}></div>
          </div>
        </div>
        <div className={styles.frame_782}>
          <div className={styles.frame_74}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
          <div className={styles.frame_75}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
          <div className={styles.frame_76}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
          <div className={styles.frame_77}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
          <div className={styles.frame_78}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
          <div className={styles.frame_792}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
          <div className={styles.frame_80}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
          <div className={styles.frame_81}>
            <div className={styles.ellipse_122}></div>
            <div className={styles.ellipse_182}></div>
            <div className={styles.ellipse_172}></div>
            <div className={styles.ellipse_162}></div>
            <div className={styles.ellipse_152}></div>
            <div className={styles.ellipse_142}></div>
            <div className={styles.ellipse_132}></div>
          </div>
        </div>
      </div>
      <div className={styles.forgot_password2}>
        <div className={styles.header}>
          <div className={styles.frame_354}>
            <div className={styles.logo}>
              <img className={styles.logo_icon} src="/images/ForgotPassword/logo-icon0.png" />
              <div className={styles.brand_name}>Appefy</div>
            </div>
            <div className={styles.title}>Forgot password?</div>
          </div>
          <div className={styles.description}>
            <div className={styles.text}>Don’t have an account?</div>
            <div className={styles.button}>
              <div className={styles.text_wrapper}>
                <div className={styles.navlink} onClick={navigateToSignup}>Sign up here</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.main}>
            <div className={styles.frame_1}>
              <div className={styles.input}>
                <div className={styles.field}>
                  <div className={styles.input_content}>
                    <div className={styles.search}><input type="text" className={styles.input_content} placeholder='Enter your email' aria-label="Email"/></div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.button_panel}>
              <div className={styles.button_frame}>
                <div className={styles.button_text}>Submit</div>
              </div>
            </div>
          </div>
          <div className={styles.continue_with}>
            <div className={styles.line_54}></div>
            <div className={styles.or_continue_with}>Or continue with</div>
            <div className={styles.line_55}></div>
          </div>
          <div className={styles.sign_up_in_with}>
            <div className={styles.sign_in_with_google}>
              <div className={styles.image_10}>
                <img className={styles.image_102} src="/images/ForgotPassword/image-101.png" />
              </div>
              <div className={styles.sign_in_with_google2}>Sign in with Google</div>
            </div>
            <div className={styles.sign_in_with_outlook}>
              <div className={styles.image_11}>
                <img className={styles.image_112} src="/images/ForgotPassword/image-111.png" />
              </div>
              <div className={styles.sign_in_with_outlook2}>Sign in with Outlook</div>
            </div>
            <div className={styles.sign_in_with_facebook}>
              <div className={styles.image_12}>
                <img className={styles.image_122} src="/images/ForgotPassword/image-121.png" />
              </div>
              <div className={styles.sign_in_with_facebook2}>Sign in with Facebook</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
    );
};

export default ForgotPassword;
