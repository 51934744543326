import React from 'react';
import styles from './Canvas.module.css'
import Navbar from '../Navbar';
import ChatWindow from '../ChatWindow';
import Editor from '../Editor';

interface CanvasProps{

}


const Canvas: React.FC<CanvasProps> = () =>{

    return(
        <div className={styles.container}>
            <div className={styles.navbar_container}>
                <Navbar stepNumber={2}/>
            </div>
            <div className={styles.editor_container}>
                <Editor/>
            </div>
       </div>
    );
}


export default Canvas;