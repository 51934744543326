import React, {useEffect, useState} from 'react';
import styles from './Navbar.module.css'
import './icons.svg'

import imgEllipse220 from './ellipse-220.png';
import imgEllipse101 from './ellipse-101.png';
import imgEllipse100 from './ellipse-100.png';

import { useNavigate } from 'react-router-dom';


interface NavbarProps{
  stepNumber : number;
}

const Navbar:React.FC<NavbarProps> = (props) => {

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkLoginStatus = () => {
            const loggedIn = localStorage.getItem('isLoggedIn') === 'true';
            setIsLoggedIn(loggedIn);
        };

        checkLoginStatus();
    }, []);

    return (
      <div className={styles.container}>
        <Logo/>
        <StepsStateCtrl stepNumber={props.stepNumber}/>
        <NotificationsCtrl/>
        {isLoggedIn ? <AccountCtrl/> : <LoginCtrl/>}
      </div>
    );

}

export default Navbar;

/*addActionButtons(){

  <div className="action">
  <div className="frame-22">
    <div className="remix-icons-line-system-eye-line">
      <svg
        className="group"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.99996 2.5C14.4933 2.5 18.2316 5.73333 19.0158 10C18.2325 14.2667 14.4933 17.5 9.99996 17.5C5.50663 17.5 1.7683 14.2667 0.984131 10C1.76746 5.73333 5.50663 2.5 9.99996 2.5ZM9.99996 15.8333C11.6995 15.833 13.3486 15.2557 14.6773 14.196C16.0061 13.1363 16.9357 11.6569 17.3141 10C16.9343 8.34442 16.0041 6.86667 14.6755 5.80835C13.3469 4.75004 11.6985 4.17377 9.99996 4.17377C8.30138 4.17377 6.65304 4.75004 5.32444 5.80835C3.99585 6.86667 3.0656 8.34442 2.6858 10C3.06421 11.6569 3.99386 13.1363 5.32258 14.196C6.65131 15.2557 8.30041 15.833 9.99996 15.8333ZM9.99996 13.75C9.0054 13.75 8.05158 13.3549 7.34831 12.6516C6.64505 11.9484 6.24996 10.9946 6.24996 10C6.24996 9.00544 6.64505 8.05161 7.34831 7.34835C8.05158 6.64509 9.0054 6.25 9.99996 6.25C10.9945 6.25 11.9484 6.64509 12.6516 7.34835C13.3549 8.05161 13.75 9.00544 13.75 10C13.75 10.9946 13.3549 11.9484 12.6516 12.6516C11.9484 13.3549 10.9945 13.75 9.99996 13.75ZM9.99996 12.0833C10.5525 12.0833 11.0824 11.8638 11.4731 11.4731C11.8638 11.0824 12.0833 10.5525 12.0833 10C12.0833 9.44747 11.8638 8.91756 11.4731 8.52686C11.0824 8.13616 10.5525 7.91667 9.99996 7.91667C9.44743 7.91667 8.91753 8.13616 8.52682 8.52686C8.13612 8.91756 7.91663 9.44747 7.91663 10C7.91663 10.5525 8.13612 11.0824 8.52682 11.4731C8.91753 11.8638 9.44743 12.0833 9.99996 12.0833Z"
          fill="#09121F"
        />
      </svg>
    </div>
    <div className="preview">Preview</div>
  </div>
  <div className="frame-24">
    <div className="remix-icons-line-device-save-2-line">
      <svg
        className="group2"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.16667 4.16667V15.8333H15.8333V6.52333L13.4767 4.16667H4.16667ZM3.33333 2.5H14.1667L17.2558 5.58917C17.4121 5.74541 17.5 5.95734 17.5 6.17833V16.6667C17.5 16.8877 17.4122 17.0996 17.2559 17.2559C17.0996 17.4122 16.8877 17.5 16.6667 17.5H3.33333C3.11232 17.5 2.90036 17.4122 2.74408 17.2559C2.5878 17.0996 2.5 16.8877 2.5 16.6667V3.33333C2.5 3.11232 2.5878 2.90036 2.74408 2.74408C2.90036 2.5878 3.11232 2.5 3.33333 2.5ZM10 15C9.33696 15 8.70107 14.7366 8.23223 14.2678C7.76339 13.7989 7.5 13.163 7.5 12.5C7.5 11.837 7.76339 11.2011 8.23223 10.7322C8.70107 10.2634 9.33696 10 10 10C10.663 10 11.2989 10.2634 11.7678 10.7322C12.2366 11.2011 12.5 11.837 12.5 12.5C12.5 13.163 12.2366 13.7989 11.7678 14.2678C11.2989 14.7366 10.663 15 10 15ZM5 5H12.5V8.33333H5V5Z"
          fill="#09121F"
        />
      </svg>
    </div>
    <div className="save">Save</div>

  </div>
  </div>
}*/

interface LogoProps{

}

const Logo:React.FC<LogoProps> = () =>{

      
  const navigate = useNavigate();

  const homePage = () =>{
    navigate('/');
  }

  return(
    <div className={styles.logo} onClick={homePage}>
    <img className="ellipse-101" src={imgEllipse100} onClick={homePage}/>
    <div className={styles.appname} onClick={homePage}>Appefy</div>
    </div>
  )
}
 
interface AccountCtrlProps{

}

const AccountCtrl: React.FC<AccountCtrlProps> = () =>{


  const navigate = useNavigate();

  const logout = () =>{
    localStorage.clear();
    navigate('/signin');  
  }

  return(
        <div className={styles.frame96}>
        <img className={styles.ellipse22} src={imgEllipse220} />
        <div className={styles.account}>Account</div>
        <div className={styles.stepicon}>
          <svg
            className={styles.group4}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99962 10.9766L14.1246 6.85156L15.303 8.0299L9.99962 13.3332L4.69629 8.0299L5.87462 6.85156L9.99962 10.9766Z"
              fill="#09121F"
            />
          </svg>
        </div>
        <div className={styles.button_panel}>
          <div className={styles.button_frame}>
            <div className={styles.button_text} onClick={logout}>
                Logout
            </div>
          </div>
        </div>
      </div>
  );
}


interface LoginCtrlProps{

}

const LoginCtrl:React.FC<LoginCtrlProps> = () =>{

  const navigate = useNavigate();

  const signin = () =>{
    navigate('/signin');
  }

  return (
    <div className={styles.button_panel}>
      <div className={styles.button_frame}>
        <div className={styles.button_text} onClick={signin}>Log in</div>
       </div>
  </div>

  );
}


interface StepsStateCtrlProps{
  stepNumber: number;
}

const RightArrowIcon = () => (
  <div className={styles.stepicon}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.1718 11.9998L9.34277 9.17184L10.7568 7.75684L14.9998 11.9998L10.7568 16.2428L9.34277 14.8278L12.1718 11.9998Z" fill="#878787" />
    </svg>
  </div>
);

const NotificationsIcon = () =>(
  <div className={styles.stepicon}>
  <svg
    className={styles.group3}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.333 11.333H14.6663V12.6663H1.33301V11.333H2.66634V6.66634C2.66634 5.25185 3.22824 3.8953 4.22844 2.89511C5.22863 1.89491 6.58519 1.33301 7.99967 1.33301C9.41416 1.33301 10.7707 1.89491 11.7709 2.89511C12.7711 3.8953 13.333 5.25185 13.333 6.66634V11.333ZM11.9997 11.333V6.66634C11.9997 5.60548 11.5782 4.58806 10.8281 3.83791C10.078 3.08777 9.06054 2.66634 7.99967 2.66634C6.93881 2.66634 5.92139 3.08777 5.17125 3.83791C4.4211 4.58806 3.99967 5.60548 3.99967 6.66634V11.333H11.9997ZM5.99967 13.9997H9.99967V15.333H5.99967V13.9997Z"
      fill="#09121F"
    />
  </svg>
</div>
);

interface StepProps{
  step: number;
  stepNumber: number;
  title: string;
}

const Step:React.FC<StepProps> = ({ step, stepNumber, title }) => {
  const stepClass = stepNumber > step ? styles.previous 
                  : stepNumber === step ? styles.active 
                  : styles.next;

  return (
    <div className={styles.step_panel}>
      <div className={`${styles.step_number} ${stepClass}`}>
        <div className={styles.number}>{step}</div>
      </div>
      <div className={`${styles.step_name} ${stepClass}`}>{title}</div>
    </div>
  );
};


const StepsStateCtrl : React.FC<StepsStateCtrlProps> = (props) =>{

  const stepNumber:number = props.stepNumber;

  return (
    <div className={styles.steps_panel}>
      <Step step={1} stepNumber={stepNumber} title="Questionnaire" />
      <RightArrowIcon/>
      <Step step={2} stepNumber={stepNumber} title="Edit" />
      <RightArrowIcon/>
      <Step step={3} stepNumber={stepNumber} title="Preview and Publish" />
     </div>
  );
}

interface NotificationsCtrlProps{

}

const NotificationsCtrl : React.FC<NotificationsCtrlProps> = () =>{
 
  return (
    <div className={styles.notifications_panel}>
        <NotificationsIcon/>
    </div>
  );
  
}